// Create a simple Lorem Ipsum page

import { Box, Typography, Stepper, Step, StepLabel, Stack, Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const style = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: 3,
    width: '100%',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.8,
    gap: 1,
    textAlign: 'center',
    width: '100%',
    mt: 'auto',
    mb: 'auto',
    p: 2,
  },
  stepper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.2,
  },
}

const StartCampaignBuilder = () => {
  const steps = [
    'Register',
    'Your Brand',
    'Product/Services',
    'Target Audience',
    'Brand Style',
    'Marketing',
  ]
  
  return (
    <Stack sx={style.root}>
      <Box sx={style.text}>
        <Typography variant="subtitle1" component="h1">
          Great! You did it.
        </Typography>

        <Typography variant="h3" component="p">
          Let's start your first campaign
        </Typography>

        <Button sx={{ mt: 6, alignSelf: 'center' }} variant="contained" color="primary">
          <Link to="/brands/campaigns/builder">Create Campaign</Link>
        </Button>
      </Box>

      <Box sx={style.stepper}>
        <Stepper activeStep={0} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Stack>
  )
}

export default StartCampaignBuilder
