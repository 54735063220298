import { Check } from '@mui/icons-material'
import {
  Box,
  Stack,
  Step,
  StepButton,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useState } from 'react'
type AestheticType = {
  aestheticData: AestheticData
}

type AestheticData = {
  Minimalist: number
  Maximalist: number
  Modern: number
  Traditional: number
  Boho: number
  Scandi: number
  Eclectic: number
  Simple: number
  New: number
  Vintage: number
  ReadyMade: number
  DIY: number
  BigBox: number
  SmallBusiness: number
  Aspirational: number
  Attainable: number
  Luxury: number
  BudgetFriendly: number
}

type UserFormProps = AestheticType & {
  updateFields: (fields: Partial<AestheticType>) => void
}

const style = {
  form: {
    mb: 2,
    width: 300,
  },
  alignment: {
    direction: 'column',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flex: 0.2,
    p: 2,
    width: '80%',
    justifyContent: 'center',
  },
}

const steps = [0, 1, 2, 3, 4]

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: theme.palette.secondary.main,
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 10,
      height: 10,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }),
)

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  )
}

const aestheticFields = [
  [
    { field: 'Minimalist', values: [4, 3, 2, 1, 0] },
    { field: 'Maximalist', values: [0, 1, 2, 3, 4] },
  ],
  [
    { field: 'Modern', values: [4, 3, 2, 1, 0] },
    { field: 'Traditional', values: [0, 1, 2, 3, 4] },
  ],
  [
    { field: 'Boho', values: [4, 3, 2, 1, 0] },
    { field: 'Scandi', values: [0, 1, 2, 3, 4] },
  ],
  [
    { field: 'Eclectic', values: [4, 3, 2, 1, 0] },
    { field: 'Simple', values: [0, 1, 2, 3, 4] },
  ],
  [
    { field: 'New', values: [4, 3, 2, 1, 0] },
    { field: 'Vintage', values: [0, 1, 2, 3, 4] },
  ],
  [
    { field: 'ReadyMade', values: [4, 3, 2, 1, 0] },
    { field: 'DIY', values: [0, 1, 2, 3, 4] },
  ],
  [
    { field: 'BigBox', values: [4, 3, 2, 1, 0] },
    { field: 'SmallBusiness', values: [0, 1, 2, 3, 4] },
  ],
  [
    { field: 'Aspirational', values: [4, 3, 2, 1, 0] },
    { field: 'Attainable', values: [0, 1, 2, 3, 4] },
  ],
  [
    { field: 'Luxury', values: [4, 3, 2, 1, 0] },
    { field: 'BudgetFriendly', values: [0, 1, 2, 3, 4] },
  ],
]

const setfields = (num1: number, num2: number) => {
  let defaultVal = -1
  switch (JSON.stringify([num1, num2])) {
    case JSON.stringify([0, 4]):
      defaultVal = 4
      break
    case JSON.stringify([3, 1]):
      defaultVal = 3
      break
    case JSON.stringify([2, 2]):
      defaultVal = 2
      break
    case JSON.stringify([1, 3]):
      defaultVal = 1
      break
    case JSON.stringify([4, 0]):
      defaultVal = 0
      break
    default:
      break
  }

  return defaultVal
}

export function AestheticForm({ aestheticData, updateFields }: UserFormProps) {
  const [value, setValue] = useState([
    setfields(aestheticData.Minimalist, aestheticData.Maximalist),
    setfields(aestheticData.Modern, aestheticData.Traditional),
    setfields(aestheticData.Boho, aestheticData.Scandi),
    setfields(aestheticData.Eclectic, aestheticData.Simple),
    setfields(aestheticData.New, aestheticData.Vintage),
    setfields(aestheticData.ReadyMade, aestheticData.DIY),
    setfields(aestheticData.BigBox, aestheticData.SmallBusiness),
    setfields(aestheticData.Aspirational, aestheticData.Attainable),
    setfields(aestheticData.Luxury, aestheticData.BudgetFriendly),
  ])

  const handleValue = (step: number, index: number) => () => {
    const newValues = [...value]
    newValues[index] = step
    setValue(newValues)

    const fieldData = aestheticFields[index]

    const firstValue = fieldData[0].values[step]
    const secondValue = fieldData[1].values[step]
    const update = {
      [fieldData[0].field]: firstValue,
      [fieldData[1].field]: secondValue,
    }

    updateFields({
      aestheticData: {
        ...aestheticData,
        ...update,
      },
    })
  }

  const aesthetic = [
    { st: 'Minimalist', cp: 'Maximalist' },
    { st: 'Modern', cp: 'Traditional' },
    { st: 'Boho', cp: 'Scandi' },
    { st: 'Eclectic', cp: 'Simple' },
    { st: 'New', cp: 'Vintage' },
    { st: 'ReadyMade', cp: 'DIY' },
    { st: 'BigBox', cp: 'SmallBusiness' },
    { st: 'Aspirational', cp: 'Attainable' },
    { st: 'Luxury', cp: 'BudgetFriendly' },
  ]
  return (
    <>
      <Stack sx={style.alignment}>
        <Box sx={style.header}>
          <Typography p={2} fontSize={30} textAlign={'center'}>
            How would you describe your aesthetic?
          </Typography>
        </Box>
        {aesthetic.map((label, index) => (
          <Box
            key={index}
            sx={{
              width: 300,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: 350,
                marginBottom: '10px',
              }}
            >
              {/* Start label */}
              <Typography>{label.st}</Typography>
              {/* End label */}
              <Typography>{label.cp}</Typography>
            </Box>
            {/* Stepper component */}
            <Stepper nonLinear activeStep={value[index]} sx={{ width: '100%' }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepButton onClick={handleValue(label, index)}>
                    <StepLabel StepIconComponent={QontoStepIcon} />
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </Box>
        ))}
      </Stack>
    </>
  )
}
