// Create a simple Lorem Ipsum page

import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Stack,
  Button,
} from '@mui/material'
import React from 'react'

const style = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: 3,
    width: '100%',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.8,
    gap: 1,
    textAlign: 'center',
    width: '100%',
    mt: 'auto',
    mb: 'auto',
    p: 2,
  },
  stepper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.2,
  },
}

export const FinalForm = () => {
  const l = 'asdfasdf'
  const steps = [
    'Online Presence',
    'Your Services',
    'Aesthetic & Vibe',
    'Brand Values',
    'Exclusions',
  ]
  const [activeStep, setActiveStep] = React.useState(steps.length)
  return (
    <Stack sx={style.root}>
      <Box sx={style.text}>
        <Typography variant="h5" component="h2">
          Great! You did it!
        </Typography>

        <Typography variant="h3" component="p" sx={{ mb: 4 }}>
        We’ll contact you as soon as we’re ready to go live with your login details.
        </Typography>
        {/* <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button variant="contained" color="primary" type="button">
            View Profile
          </Button>
        </Box> */}
      </Box>

      <Box sx={style.stepper}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Stack>
  )
}
