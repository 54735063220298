import {
  GiftingTypeSchemaType,
} from '@lib/types/campaign-builder-zod'
import {
  Box,
  Stack,
  TextField,
  Typography,
  styled,
  Tab,
  Tabs,
  Slider,
} from '@mui/material'
import { useState } from 'react'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'

const AntTabs = styled(Tabs)({
  // borderBottom: "1px solid #e8e8e8",
  // height: 'auto',
  padding: 0,
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
})

interface StyledTabProps {
  label: string
}

const AntTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  minWidth: theme.spacing(11),
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.85)',
  border: '2px solid #e8e8e8',
  '&:hover': {
    color: theme.palette.text.primary,
    opacity: 1,
  },
  '&.Mui-selected': {
    color: theme.palette.secondary.contrastText,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: theme.palette.secondary.main,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}))



type GiftingTypeFormProps = {
  control: Control<GiftingTypeSchemaType, any>
  setValue: UseFormSetValue<GiftingTypeSchemaType>
}

const style = {
  form: {
    mb: 2,
  },
  alignment: {
    direction: 'column',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flex: 0.2,
    p: 2,
    gap: 2,
    width: '80%',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sub_alignment: {
    mt: 6,
    p: 2,
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}

const giftingTypes = [
  {
    value: 'monetary',
    label: 'Monetary',
  },
  {
    value: 'product',
    label: 'Product Gifting',
  },
  {
    value: 'both',
    label: 'Both',
  },
]



export function GiftingTypeForm({ control, setValue }: GiftingTypeFormProps) {
  const [giftingType, setGiftingType] = useState(
    control._formValues.giftingType ?? 'monetary'
  )

  const getGiftingTypeIndex = (giftingType: string) => {
    switch (giftingType) {
      case 'monetary':
        return 0
      case 'product':
        return 1
      case 'both':
        return 2
      default:
        return -1
    }
  }

  return (
    <>
      <Stack sx={style.alignment}>
        <Box sx={style.header}>
          <Typography variant="h4" component={'h2'} textAlign={'center'}>
            What are you budgeting for this campaign?
          </Typography>
          <Controller
            name="giftingType"
            control={control}
            render={({
              field: { value, onChange },
            }) => (
              <AntTabs
                value={getGiftingTypeIndex(value)}
                onChange={(e, newValue) => {
                  switch (newValue) {
                    case 0:
                      setGiftingType('monetary')
                      onChange('monetary')
                      setValue('totalProductValue', 0)
                      setValue('totalProductQuantity', 0)
                      break
                    case 1:
                      setGiftingType('product')
                      onChange('product')
                      setValue('totalMonetaryValue', 0)
                      break
                    case 2:
                      setGiftingType('both')
                      onChange('both')
                      break
                    default:
                      onChange('')
                  }
                }}
                aria-label="ant example"
                sx={{ mb: 2 }}
              >
                {giftingTypes.map((item, index) => (
                  <AntTab label={item.label} key={index} />
                ))}
              </AntTabs>
            )}
          />
        </Box>
        <Box sx={style.header}>
          <Typography variant="h4" component={'h2'} textAlign={'center'}>
            Great, please describe:
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                justifyContent: 'center',
              }}
            >
              <Controller
                name="totalProductValue"
                control={control}
                render={({
                  field: { value, onChange, onBlur, ref },
                  fieldState: { error },
                }) => (
                  <TextField
                    disabled={
                      giftingType !== 'product' && giftingType !== 'both'
                    }
                    error={!!error}
                    onChange={(e) => onChange(parseFloat(e.target.value))}
                    onBlur={onBlur}
                    value={value}
                    inputRef={ref}
                    helperText={error ? error.message : null}
                    type="number"
                    label="Total Product Value (USD)"
                    id="totalProductValue"
                    variant="outlined"
                    size="small"
                    sx={{ my: 1 }}
                  />
                )}
              />
              <Controller
                name="totalProductQuantity"
                control={control}
                render={({
                  field: { value, onChange, onBlur, ref },
                  fieldState: { error },
                }) => (
                  <TextField
                    disabled={
                      giftingType !== 'product' && giftingType !== 'both'
                    }
                    error={!!error}
                    onChange={(e) => onChange(parseInt(e.target.value))}
                    onBlur={onBlur}
                    value={value}
                    inputRef={ref}
                    helperText={error ? error.message : null}
                    type="number"
                    label="Total Product Quantity"
                    id="totalProductQuantity"
                    variant="outlined"
                    size="small"
                    sx={{ my: 1 }}
                  />
                )}
              />
            </Box>
            <Box>
              <Typography
                variant="subtitle2"
                component={'p'}
                textAlign={'center'}
              >
                Total Campaign (Financial) Budget
              </Typography>

              <Controller
                name="totalMonetaryValue"
                control={control}
                render={({
                  field: { value, onChange },
                }) => (
                  <Box sx={{ m: 2 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography id="input-slider" gutterBottom>
                        $0
                      </Typography>
                      <Typography id="input-slider" gutterBottom>
                        $1,000,000
                      </Typography>
                    </Box>
                    <Stack spacing={1}>
                      <Slider
                        disabled={giftingType !== 'monetary' && giftingType !== 'both'}
                        value={typeof value === 'number' ? value : 0}
                        onChange={(e: any) => onChange(Number(e.target.value))}
                        aria-label="Monetary Budget"
                        defaultValue={0}
                        // getAriaValueText={valuetext}
                        step={10000}
                        marks
                        min={0}
                        max={1000000}
                        valueLabelDisplay="auto"
                      />
                      <Typography gutterBottom align="center" fontWeight="bold">
                        {'$' + value}
                      </Typography>
                    </Stack>
                  </Box>
                )}
              />
            </Box>
          </Box>
        </Box>
      </Stack>
    </>
  )
}
