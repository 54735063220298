// Create a simple Lorem Ipsum page

import { MarketingExperienceSchemaType } from '@lib/types/zod-schema'
import {
  Box,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
  Checkbox,
  Switch,
  Autocomplete,
  FormControl,
  FormHelperText,
} from '@mui/material'
import { type } from 'os'
import { useEffect, useState } from 'react'
import { Control, Controller, Form } from 'react-hook-form'

type OnlinePresence2FormProps = {
  control: Control<MarketingExperienceSchemaType, any>
  contentText: ContentText
}

type ContentText = {
  header: string
  subheader: string
}

const style = {
  form: {
    mb: 2,
  },
  alignment: {
    direction: 'column',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flex: 0.2,
    p: 2,
    width: '80%',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sub_alignment: {
    mt: 6,
    p: 2,
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}

export function OnlinePresenceForm2({
  control,
  contentText,
}: OnlinePresence2FormProps) {
  const activities = [
    'Blogging',
    'Vlogging',
    'Podcast',
    'Long-form video',
    'Short-form video',
    'Photography',
    'Email newsletter',
    'Curation/Designer Picks',
    'Live events',
    'Live videos on social media',
  ]

  const contentTypes = [
    'Video',
    'Photo',
    'Case Studies',
    'Generative AI/Midjourney',
    'Email/Newsletter',
    'Blog',
  ]

  return (
    <>
      <Stack sx={style.alignment}>
        <Box sx={style.header}>
          <Typography variant="h4" component={'h2'} textAlign={'center'}>
            {contentText.header}
          </Typography>
        </Box>
        <Controller
          name="yearsExperience"
          control={control}
          render={({
            field: { value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <TextField
              required
              error={!!error}
              onChange={(e) => onChange(parseInt(e.target.value))}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              helperText={error ? error.message : null}
              type="number"
              label="Years in design"
              id="yearsExperience"
              variant="outlined"
              size="small"
              sx={{ m: 4 }}
            />
          )}
        />
        <Box
          sx={{
            display: 'flex',
            flex: 0.2,
            p: 2,
            width: '80%',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            fontWeight="bold"
            variant="h5"
            component="h3"
            textAlign={'center'}
          >
            {contentText.subheader}
          </Typography>
          <Controller
            name="marketingActivities"
            control={control}
            render={({
              field: { value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <Autocomplete
                sx={{ p: 2 }}
                multiple
                onBlur={onBlur}
                options={activities}
                value={value}
                onChange={(event, value) => onChange(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select"
                    error={Boolean(error)}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            )}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flex: 0.2,
            p: 2,
            width: '80%',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            fontWeight="bold"
            variant="h5"
            component="h3"
            textAlign={'center'}
          >
            What type of content do you organically share?
          </Typography>
          <Controller
            name="content"
            control={control}
            render={({
              field: { value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <Autocomplete
                sx={{ p: 2 }}
                multiple
                onBlur={onBlur}
                options={contentTypes}
                value={value}
                onChange={(event, value) => onChange(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select"
                    error={Boolean(error)}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            )}
          />
        </Box>
        <Typography
          fontWeight="bold"
          variant="h5"
          component="h3"
          textAlign={'center'}
        >
          Do you work with a talent manager and/or production team?
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            mt: 3,
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <Controller
            name="currentlyInAgency"
            control={control}
            render={({
              field: { value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <FormControlLabel
                control={
                  <Switch
                    id="currentlyInAgency"
                    color={value ? 'primary' : 'secondary'}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    onChange={onChange}
                    onBlur={onBlur}
                    checked={value}
                    inputRef={ref}
                  />
                }
                label={
                  <Typography variant="body2" component="p">
                    A marketing agency or outside representation handles my
                    content creation for me
                  </Typography>
                }
              />
            )}
          />

          <Controller
            name="inHouseContent"
            control={control}
            render={({
              field: { value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <FormControlLabel
                control={
                  <Switch
                    id="inHouseContent"
                    color={value ? 'primary' : 'secondary'}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    onChange={onChange}
                    onBlur={onBlur}
                    checked={value}
                    inputRef={ref}
                  />
                }
                label={
                  <Typography variant="body2" component="p">
                    Content is created in-house
                  </Typography>
                }
              />
            )}
          />
        </Box>
      </Stack>
    </>
  )
}
