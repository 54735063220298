// Create a simple Lorem Ipsum page

import { ValuesFormSchemaType } from '@lib/types/zod-schema'
import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { type } from 'os'
import { useEffect, useState } from 'react'
import { Control, Controller } from 'react-hook-form'

type ValuesFormProps = {
  control: Control<ValuesFormSchemaType, any>
  contentText: ContentText
}

type ContentText = {
  header: string
  subheader: string
}

const style = {
  form: {
    mb: 2,
    width: 300,
  },
  alignment: {
    direction: 'column',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flex: 0.2,
    p: 2,
    width: '80%',
    justifyContent: 'center',

    flexDirection: 'column',
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sub_alignment: {
    mt: 6,
    p: 2,
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}

export function ValuesForm({ control, contentText }: ValuesFormProps) {
  const values = [
    'Diversity, equity and inclusion',
    'Religion and spirituality',
    'Environmental Stewardship and Sustainability',
    'Animal rights',
    'Family',
    'Body Positivity',
    'Awareness of a Health Coalition',
    'LGBTQIA+ rights',
    'Volunteerism',
    'Social Activism',
  ]

  return (
    <>
      <Stack sx={style.alignment}>
        <Box sx={style.header}>
          <Typography p={2} fontSize={30} textAlign={'center'}>
            {contentText.header}
          </Typography>
          <Typography p={2} fontSize={15} textAlign={'center'}>
            {contentText.subheader}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            width={300}
            mb={1}
            mt={2}
          >
            Give brief descriptions as needed.
          </Typography>
          <Controller
            name="valueDesc"
            control={control}
            render={({
              field: { value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                multiline
                rows={4}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                helperText={error ? error.message : null}
                type="text"
                label="Value Description"
                variant="outlined"
                sx={style.form}
                id="valueDesc"
              />
            )}
          />
        </Box>
        <Typography
          variant="body1"
          component="p"
          sx={{
            width: 300,
            mb: 3,
            textAlign: 'center',
          }}
        >
          Does your business share content around these values? Choose all that
          apply.
        </Typography>

        <Controller
          name="value"
          control={control}
          render={({
            field: { value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <Autocomplete
              id="vibes"
              onChange={(e, selectedValue) => {
                onChange(selectedValue)
              }}
              multiple
              onBlur={onBlur}
              value={value}
              options={values}
              sx={style.form}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  error={Boolean(error)}
                  helperText={error ? error.message : null}
                />
              )}
            />
          )}
        />
        {/* {selectedValues.includes('Political') ? (
          <>
            <Typography
              variant="body2"
              color="text.secondary"
              width={300}
              mb={1}
              mt={2}
            >
              You selected political, what causes would you be willing to
              collaborate with brands in service of:
            </Typography>
            <TextField
              id="serviceDescription"
              label="Describe here..."
              multiline
              rows={4}
              variant="outlined"
              value={politicalDesc}
              onChange={(e) =>
                updateFields({
                  politicalDesc: e.target.value,
                })
              }
              fullWidth
              sx={{ mb: 2, width: 300 }}
            />
          </>
        ) : null} */}
      </Stack>
    </>
  )
}
