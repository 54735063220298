export function storeObject(key: string, obj: any): void {
    const serializedObj = JSON.stringify(obj);
    localStorage.setItem(key, serializedObj);
  }
  
  export function fetchObject(key: string): any {
    const serializedObj = localStorage.getItem(key);
    if (serializedObj === null) {
      return null;
    }
    return JSON.parse(serializedObj);
  }