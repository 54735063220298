// Create a simple Lorem Ipsum page

import { FormContainer } from '@components/ui/StyledComponents'
import { SocialFormSchemaType } from '@lib/types/zod-schema'
import { Box, Stack, TextField, Typography } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { useEffect, useState } from 'react'
type UserFormProps = {
  control: Control<SocialFormSchemaType, any>
}

const style = {
  textField: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
}

export function OnlinePresenceForm({ control }: UserFormProps) {
  const [socials, setSocials] = useState([
    {
      socialValue: control._formValues.socials?.instagram,
      socials: 'socials.instagram.url' as const,
      followers: 'socials.instagram.followers' as const,
      socialType: 'text',
      countType: 'number',
      label: 'Instagram Account',
      value: '',
    },
    {
      socialValue: control._formValues.socials?.twitter,
      socials: 'socials.twitter.url' as const,
      followers: 'socials.twitter.followers' as const,
      socialType: 'text',
      countType: 'number',
      label: 'Twitter Account',
      value: '',
    },
    {
      socialValue: control._formValues.socials?.linkedin,
      socials: 'socials.linkedin.url' as const,
      followers: 'socials.linkedin.followers' as const,
      socialType: 'text',
      countType: 'number',
      label: 'LinkedIn Account',
      value: '',
    },
    {
      socialValue: control._formValues.socials?.pinterest,
      socials: 'socials.pinterest.url' as const,
      followers: 'socials.pinterest.followers' as const,
      socialType: 'text',
      countType: 'number',
      label: 'Pinterest Account',
      value: '',
    },
    {
      socialValue: control._formValues.socials?.facebook,
      socials: 'socials.facebook.url' as const,
      followers: 'socials.facebook.followers' as const,
      socialType: 'text',
      countType: 'number',
      label: 'Facebook Account',
      value: '',
    },
    {
      socialValue: control._formValues.socials?.tiktok,
      socials: 'socials.tiktok.url' as const,
      followers: 'socials.tiktok.followers' as const,
      socialType: 'text',
      countType: 'number',
      label: 'TikTok Account',
      value: '',
    },

    {
      socialValue: control._formValues.socials?.youtube,
      socials: 'socials.youtube.url' as const,
      followers: 'socials.youtube.followers' as const,
      socialType: 'text',
      countType: 'number',
      label: 'Youtube Account',
      value: '',
    },
  ])

  useEffect(() => {
    setSocials((prevSocials) =>
      prevSocials.map((social) => ({
        ...social,
        value: social.socialValue?.url ?? '',
      })),
    )
  }, [control._formValues.socials])

  const updateSocialAtIndex = (index: number, newValue: string) => {
    setSocials((prevSocials) => {
      const newSocials = [...prevSocials]

      newSocials[index] = {
        ...newSocials[index],
        value: newValue,
      }

      return newSocials
    })
  }

  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'column',

        width: '100%',
        mx: 'auto',
        alignSelf: 'stretch',
      }}
      spacing={2}
    >
      <Box
        sx={{
          flexShrink: 1,
        }}
      >
        <Typography my={2} textAlign={'center'} variant="h4" component="h2">
          Tell us about your social life.
        </Typography>
      </Box>
      <FormContainer>
        {socials.map((social, index) => {
          return (
            <>
              <Box sx={style.textField} key={index}>
                <Controller
                  name={social.socials}
                  control={control}
                  render={({
                    field: { value, onChange, onBlur, ref },
                    fieldState: { error },
                  }) => (
                    <TextField
                      error={!!error}
                      onChange={onChange}
                      onBlur={() => {
                        {
                          onBlur()
                        }
                        {
                          updateSocialAtIndex(index, value ?? '')
                        }
                      }}
                      value={value}
                      inputRef={ref}
                      helperText={error ? error.message : null}
                      type={social.socialType}
                      label={social.label}
                      id={social.socials}
                      variant="outlined"
                      size="medium"
                    />
                  )}
                />
                <Controller
                  name={social.followers}
                  control={control}
                  render={({
                    field: { value, onChange, onBlur, ref },
                    fieldState: { error },
                  }) => (
                    <TextField
                      disabled={!social.value}
                      error={!!error}
                      onChange={(e) => onChange(parseInt(e.target.value))}
                      onBlur={onBlur}
                      value={value}
                      inputRef={ref}
                      helperText={error ? error.message : null}
                      type={social.countType}
                      label="Total Followers (#)"
                      id={social.followers}
                      variant="outlined"
                      size="medium"
                    />
                  )}
                />
              </Box>
            </>
          )
        })}
      </FormContainer>
    </Stack>
  )
}
