import { fetchCampaignRecommendations } from '@lib/services/campaign'
import { fetchObject } from '@lib/utils/storage'
import {
  Button,
  LinearProgress,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useParams } from 'react-router-dom'
import remarkGfm from 'remark-gfm'
import './gfm.css'

const CampaignPlan = () => {
  const { plan_key } = useParams<{ plan_key: string }>()
  const formAnswers: any = plan_key
    ? fetchObject(`campaignPlanInput_${plan_key}`)
    : null
  const [error, setError] = React.useState<boolean>(false)
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)
  const [campaignMd, setCampaignMd] = React.useState<string>('')

  const fetchCampaignPlan = React.useCallback(() => {
    if (formAnswers) {
      setLoading(true)
      return fetchCampaignRecommendations(formAnswers, 'campaign-plan')
        .then((res) => {
          console.log('res', res)
          setCampaignMd(res)
        })
        .catch((err: any) => {
          setError(true)
          setErrorMessage(
            'Sorry, we failed to fetch your recommendations. Please try again.',
          )
        })
        .finally(() => setLoading(false))
    } else {
      setError(true)
      setErrorMessage(
        'Sorry, we could not find your answers. Please try again.',
      )
    }
  }, [formAnswers])

  const downloadMd = () => {
    const blob = new Blob([campaignMd], { type: 'text/markdown' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'campaign.md';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  React.useEffect(() => {
    fetchCampaignPlan()
  }, [])

  const skeleton = (
    <>
      <LinearProgress color="secondary" />
      
      <Skeleton variant="text" width={'80%'} sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" width={'50%'} sx={{ fontSize: '2rem' }} />
      <br />
      <Skeleton variant="text" width={'40%'} sx={{ fontSize: '1.5rem' }} />
      <Skeleton variant="text" width={'100%'} sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" width={'100%'} sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" width={'100%'} sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" width={'100%'} sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" width={'60%'} sx={{ fontSize: '1rem' }} />
      <br />
      <Skeleton variant="text" width={'30%'} sx={{ fontSize: '1.5rem' }} />
      <Skeleton variant="text" width={'100%'} sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" width={'100%'} sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" width={'100%'} sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" width={'100%'} sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" width={'60%'} sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" width={'100%'} sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" width={'100%'} sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" width={'100%'} sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" width={'60%'} sx={{ fontSize: '1rem' }} />
      
    </>
  )

  return (
    <>
      <Paper
        title='Campaign Plan'
        elevation={3}
        sx={{ display: 'flex', flexDirection: 'column', p: 1.5, width: '100%', minHeight: 600 }}
      >
        {loading ? (
          skeleton
        ) : error ? (
          <Typography variant="body2" sx={{ color: 'red' }}>
            {errorMessage}
          </Typography>
        ) : (
          <>
          
          <ReactMarkdown children={campaignMd} remarkPlugins={[remarkGfm]} />
          <Button onClick={downloadMd}>Download</Button>
          </>
        )}
      </Paper>
    </>
  )
}

export default CampaignPlan
