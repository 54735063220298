import { gql, useQuery } from '@apollo/client'
import { Avatar, Box, Checkbox, Grid, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import { useEffect, useState } from 'react'
import ProfileInfoModal from '../../../components/common/Modals/ProfileInfoModal'
import { DesignerReco } from '../../../../../common/api-types'
import { Control, Controller } from 'react-hook-form'
import { SelectedDesignersSchemaType } from '@lib/types/campaign-builder-zod'
import { on } from 'events'
import { useNavigate } from 'react-router-dom'

type DesignerRecoProps = {
  control: Control<SelectedDesignersSchemaType, any>
  brandData: {
    name: string
    email: string
  }
}

function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  }
}

const GET_DATA = gql`
  query DesignerReco($where: DesignerRecoInput) {
    designerReco(where: $where) {
      designer_name
      total_reach
      total_score
      styles
      values
      vibes
      content
      activities
      email
    }
    topDesignersSocialReach {
      designer_name
      total_reach
      total_score
      styles
      values
      vibes
      content
      activities
      email
    }
  }
`

export function DesignerRecommendationForm({
  control,
  brandData,
}: DesignerRecoProps) {
  const navigate = useNavigate()
  const { name, email } = brandData
  const { loading, error, data } = useQuery(GET_DATA, {
    variables: {
      where: {
        name: name,
        email: email,
      },
    },
  })

  useEffect(() => {
    // Redirect to '/brands' if 'brandData' is not present or doesn't have the expected properties
    if (!brandData || !brandData.name || !brandData.email) {
      navigate('/brands')
    }
  }, [brandData, navigate])

  const [selectedCards, setSelectedCards] = useState<DesignerReco[]>(
    control._formValues.selectedDesigners,
  ) // State to store selected card IDs
  const [modalKey, setModalKey] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState<DesignerReco | null>(null)
  const handleCloseModal = () => {
    setModalData(null)
    setIsModalOpen(false)
  }
  const handleRowClick = (row: DesignerReco) => {
    setModalKey(modalKey + 1)
    setModalData(row)
  }

  const handleCardSelect = (id: DesignerReco, onChange: any) => {
    setSelectedCards((prevSelectedCards) => {
      const isAlreadySelected = prevSelectedCards.includes(id)

      const updatedSelectedCards = isAlreadySelected
        ? prevSelectedCards.filter((selectedId) => selectedId !== id)
        : [...prevSelectedCards, id]

      onChange(updatedSelectedCards)

      console.log('updatedSelectedCards', updatedSelectedCards)

      return updatedSelectedCards
    })
  }
  if (loading) return <p>Loading...</p>
  if (error) return <p>Error :</p>

  const recoData = () => {
    if (data?.designerReco.length > 0) {
      return data?.designerReco
    } else {
      return data?.topDesignersSocialReach
    }
  }
  const reco = recoData()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: 5,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 5,
        }}
      >
        <Typography variant="h4" component="div">
          <Box sx={{ fontWeight: 'bold' }}>Designer Recommendations</Box>
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            textAlign: 'center',
            maxWidth: '50%',
            mt: 2,
          }}
        >
          Based on your brand's values and attributes, we recommend the
          following designers.
        </Typography>
      </Box>
      <Controller
        name="selectedDesigners"
        control={control}
        render={({
          field: { value, onChange, onBlur, ref },
          fieldState: { error },
        }) => (
          <>
            {error && (
              <Typography
                variant="body2"
                color="error"
                sx={{
                  maxWidth: '50%',
                  mb: 2,
                }}
              >
                {error.message}
              </Typography>
            )}
            <Grid container spacing={3} justifyContent="center">
              {reco.map((designer: any, index: number) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
                  <Card
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'relative', // Ensure the parent is relatively positioned for absolute positioning
                      backgroundColor: selectedCards.includes(designer)
                        ? '#e1f5fe'
                        : 'inherit', // Highlight the card when checked
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        backgroundColor: 'transparent',
                      }}
                    >
                      <Checkbox
                        checked={selectedCards.includes(designer)}
                        onChange={() => {
                          handleCardSelect(designer, onChange)
                          onBlur()
                        }}
                      />
                    </Box>
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          bgcolor: 'background.paper',
                          p: 1,
                          backgroundColor: selectedCards.includes(
                            designer.designer_name,
                          )
                            ? '#e1f5fe'
                            : 'inherit', // Highlight the card when checked
                        }}
                      >
                        <Avatar
                          {...stringAvatar(designer.designer_name)}
                          sx={{ width: 50, height: 50 }}
                        />
                      </Box>
                      <Typography gutterBottom variant="h5" component="div">
                        {designer.designer_name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                      ></Typography>
                    </CardContent>

                    <CardActions>
                      <Button
                        size="small"
                        onClick={() => {
                          handleRowClick(designer)
                          setIsModalOpen(true)
                        }}
                      >
                        View Profile
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      />
      {modalData && (
        <ProfileInfoModal
          key={modalKey}
          open={isModalOpen}
          onClose={handleCloseModal}
          data={modalData}
        />
      )}
    </Box>
  )
}
