import { z } from 'zod'

export enum GiftingAmount {
  Zero = '$0',
  OneThousand = '$1000+',
  FiveThousand = '$5000+',
  TenThousand = '$10,000+',
  TwentyFiveThousand = '$25,000+',
  // FiftyThousand = '$50,000+',
}

/* CAMPAIGN FORM SCHEMAS */
export const budgetDescriptionSchema = z.object({
  monetaryBudgetDesc: z
    .string()
    .min(5, { message: 'Description must be more than 5 characters' })
    .max(1000, { message: 'Description must be less than 1000 characters' }),
  productGiftBudgetDesc: z
    .string()
    .min(5, { message: 'Description must be more than 5 characters' })
    .max(1000, { message: 'Description must be less than 1000 characters' }),
})

export type BudgetDescriptionSchemaType = z.infer<
  typeof budgetDescriptionSchema
>

export const giftingTypeSchema = z.object({
  giftingType: z.enum(['monetary', 'product', 'both']).default('product'),
  totalProductValue: z
    .number()
    .min(0, { message: 'Must be non-negative number' })
    .default(0),
  totalProductQuantity: z
    .number()
    .min(0, { message: 'Must be non-negative number' })
    .default(0),
  totalMonetaryValue: z
    .number()
    .min(0, { message: 'Must be non-negative number' })
    .max(1000000, { message: 'Must be less than 1,000,000' }),
})

export type GiftingTypeSchemaType = z.infer<typeof giftingTypeSchema>

export const campaignProductSchema = z.object({
  productName: z
    .string()
    .min(5, { message: 'Product name must be more than 5 characters' })
    .max(100, { message: 'Product name must be less than 100 characters' }),
  productDescription: z.optional(
    z
      .string()
      .max(500, { message: 'Description must be less than 1000 characters' }),
  ),
})

export const campaignProductListSchema = z.object({
  products: z
    .array(campaignProductSchema)
    .min(1, { message: 'Must have at least one product' }),
})

export type CampaignProductSchemaType = z.infer<typeof campaignProductSchema>

export type CampaignProductListSchemaType = z.infer<
  typeof campaignProductListSchema
>

// Campaign Goals
export const campaignGoalsSchema = z.object({
  campaignGoals: z
    .array(z.string())
    .min(1, { message: 'Must have at least one goal' }),
  kpis: z
    .string()
    .max(1000, { message: 'Description must be less than 1000 characters' }),
  // must be comma separated list of strings
  // .regex(/^(\w+)(,\s*\w+)*$/, { message: 'Must be comma separated list of strings' }),
})

export type CampaignGoalsSchemaType = z.infer<typeof campaignGoalsSchema>

// Campaign Concepts
export const campaignConceptsSchema = z
  .object({
    campaignConceptsDescription: z
      .string()
      .max(1000, { message: 'Description must be less than 1000 characters' }),
    timelineStartDate: z.string().datetime({ message: 'Must be a valid date' }),
    timelineEndDate: z.string().datetime({ message: 'Must be a valid date' }),
  })
  // end date must be after start date
  .refine(
    (data) => {
      const startDate = new Date(data.timelineStartDate)
      const endDate = new Date(data.timelineEndDate)
      return endDate > startDate
    },
    { message: 'End date must be after start date' },
  )

export type CampaignConceptsSchemaType = z.infer<typeof campaignConceptsSchema>

// Campaign Style
export const campaignStylesSchema = z.object({
  campaignStyles: z.object({
    minimalistMaximalist: z.object({
      minimalist: z.number().min(0).max(4),
      maximalist: z.number().min(0).max(4),
    }),
    modernTraditional: z.object({
      modern: z.number().min(0).max(4),
      traditional: z.number().min(0).max(4),
    }),
    bohoScandinavian: z.object({
      boho: z.number().min(0).max(4),
      scandinavian: z.number().min(0).max(4),
    }),
    eclecticSimple: z.object({
      eclectic: z.number().min(0).max(4),
      simple: z.number().min(0).max(4),
    }),
    newVintage: z.object({
      new: z.number().min(0).max(4),
      vintage: z.number().min(0).max(4),
    }),
    readymadeDIY: z.object({
      readymade: z.number().min(0).max(4),
      diy: z.number().min(0).max(4),
    }),
    bigboxSmallBusiness: z.object({
      bigbox: z.number().min(0).max(4),
      smallbusiness: z.number().min(0).max(4),
    }),
    aspirationalAttainable: z.object({
      aspirational: z.number().min(0).max(4),
      attainable: z.number().min(0).max(4),
    }),
    luxuryBudget: z.object({
      luxury: z.number().min(0).max(4),
      budget: z.number().min(0).max(4),
    }),
  }),
})

export type CampaignStylesSchemaType = z.infer<typeof campaignStylesSchema>

// Campaign Audience
export const campaignAudienceSchema = z.object({
  campaignShowcaseLocation: z
    .array(z.string())
    .min(1, { message: 'Must have at least one goal' }),
  audienceDescription: z
    .string()
    .min(5, { message: 'Description must be more than 5 characters' })
    .max(1000, { message: 'Description must be less than 1000 characters' }),
})

export type CampaignAudienceSchemaType = z.infer<typeof campaignAudienceSchema>

// Selected Designers
export const selectedDesignersSchema = z.object({
  selectedDesigners: z
    .array(
      z.object({
        designer_name: z.string(),
        email: z.string().email(),
        activities: z.array(z.string()),
        content: z.array(z.string()),
        values: z.array(z.string()),
        vibes: z.array(z.string()),
        styles: z.array(z.string()),
        total_reach: z.number().min(0),
        total_score: z.number().min(0).nullable(),
      }),
    )
    .min(1, { message: 'Must have at least one designer' })
    .max(3, { message: 'Maximum of 3 designer' })
    .default([]),
})

export type SelectedDesignersSchemaType = z.infer<
  typeof selectedDesignersSchema
>
