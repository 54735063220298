import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { InclusionExclusionFormSchemaType } from '@lib/types/zod-schema'
import { Control, Controller } from 'react-hook-form'

type InclusionExclusionProps = {
  control: Control<InclusionExclusionFormSchemaType, any>
}

// type ExclusionData = {
//   exclusionDesc: string
//   inclusionDesc: string
// }

// type UserFormProps = ExclusionData & {
//   updateFields: (fields: Partial<ExclusionData>) => void
// }
type ListType = {
  [key: string]: string[]
}
const style = {
  form: {
    mb: 2,
    width: 300,
  },
  alignment: {
    direction: 'column',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flex: 0.2,
    p: 2,
    width: '80%',
    justifyContent: 'center',

    flexDirection: 'column',
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sub_alignment: {
    mt: 6,
    p: 2,
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}

const list: ListType = {
  'Style / Aesthetic': [
    'Minimalist',
    'Maximalist',
    'Modern',
    'Traditional',
    'Coastal',
    'Farmhouse',
    'Luxury',
    'Economical',
  ],
  'Brand Values': [
    'Inclusive / DE&I',
    'Sustainability',
    'Environmentally Conscious',
    'Animal-Lover',
    'Political',
  ],
  Vibe: [
    'Activist',
    'Empathic',
    'Retro',
    'Gothic',
    'Futuristic',
    'Inclusive',
    'Romantic',
    'Fun & Friendly',
    'Formal & Elegant',
    'Happy & Colorful',
    'Risk-taker',
    'Zen',
    'Dark / moody',
    'Traditional',
  ],
}

export function ExclusionForm(control: InclusionExclusionProps) {
  // const [selected, setSelected] = useState<[string[], string[], string[]]>([
  //   [],
  //   [],
  //   [],
  // ])
  // const [expanded, setExpanded] = useState([false, false, false])

  // useEffect(() => {
  //   setSelected([excludedStyle, excludedValue, excludedVibe])
  // }, [excludedStyle, excludedValue, excludedVibe])

  // const handleChange = (value: string[], index: number) => {
  //   switch (index) {
  //     case 0:
  //       updateFields({
  //         excludedStyle: value,
  //       })
  //       break
  //     case 1:
  //       updateFields({
  //         excludedValue: value,
  //       })
  //       break
  //     case 2:
  //       updateFields({
  //         excludedVibe: value,
  //       })
  //       break
  //     default:
  //       break
  //   }
  // }

  // const handleExpand = (index: number) => {
  //   const newExpanded = [...expanded]
  //   newExpanded[index] = !newExpanded[index]
  //   setExpanded(newExpanded)
  // }

  return (
    <>
      <Stack sx={style.alignment}>
        <Box sx={style.header}>
          <Typography p={2} fontSize={25} textAlign={'center'}>
            {' '}
            What type of brand partnerships are you looking for?
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            width={300}
            mb={1}
            mt={2}
          >
            Please describe in as little or as much detail as you would like
          </Typography>
          <Controller
            name="inclusionDesc"
            control={control.control}
            render={({
              field: { value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                multiline
                rows={4}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                helperText={error ? error.message : null}
                type="text"
                label="Your Description..."
                variant="outlined"
                sx={style.form}
              />
            )}
          />
          {/* <TextField
            id="exclusionDescription"
            label="Your Description..."
            multiline
            rows={4}
            variant="outlined"
            value={inclusionDesc}
            onChange={(e) =>
              updateFields({
                inclusionDesc: e.target.value,
              })
            }
            fullWidth
            sx={{ mb: 2, width: 300 }}
          /> */}
        </Box>

        <Box sx={style.header}>
          <Typography p={2} fontSize={25} textAlign={'center'}>
            {' '}
            What type of brand partnerships are you NOT looking for?
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            width={300}
            mb={1}
            mt={2}
          >
            Please describe in as little or as much detail as you would like
          </Typography>
          <Controller
            name="exclusionDesc"
            control={control.control}
            render={({
              field: { value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                multiline
                rows={4}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                helperText={error ? error.message : null}
                type="text"
                label="Your Description..."
                variant="outlined"
                sx={style.form}
              />
            )}
          />
          {/* <TextField
            id="exclusionDescription"
            label="Your Description..."
            multiline
            rows={4}
            variant="outlined"
            value={exclusionDesc}
            onChange={(e) =>
              updateFields({
                exclusionDesc: e.target.value,
              })
            }
            fullWidth
            sx={{ mb: 2, width: 300 }}
          /> */}
        </Box>
        {/* {Object.keys(list).map((key, index) => (
          <>
            <Box
              key={index}
              sx={{
                mb: 2,
              }}
            >
              <Autocomplete
                multiple
                limitTags={3}
                id="multiple-limit-tags"
                options={list[key]}
                // getOptionLabel={(option) => option.title}
                value={selected[index]}
                onChange={(event, value) => handleChange(value, index)}
                renderInput={(params) => (
                  <TextField {...params} label={key} placeholder={key} />
                )}
                sx={{ width: '500px' }}
              />
            </Box>
          </>
        ))} */}
      </Stack>
    </>
  )
}
