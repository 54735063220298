const apiHost = (process.env.REACT_APP_CAMPAIGN_BUILDER_URL as string) || ''

export const fetchCampaignRecommendations = async (
  questionaireAnswers: any,
  path: string,
): Promise<any> => {
  if (!apiHost) {
    throw new Error('Campaign Builder URL not defined')
  }

  const url = `${apiHost}/${path}`
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // 'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(questionaireAnswers),
  })
  const data = await response.json()
  return data.data.answer
}
