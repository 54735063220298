import { Box, styled } from "@mui/material";

export const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  width: '100%',
  mt: 'auto',
  mb: 'auto',
  flexGrow: 1,
}))
