import {
  Box,
  Button,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
} from '@mui/material'
import { FormEvent, useEffect, useState } from 'react'

import { useMultistepForm } from '../../../lib/hooks/useMultistepForm'
import { AestheticForm } from '../AestheticForm'
import OnboardingNotes from '../OnboardingNotes'
import { ValuesForm } from '../ValuesForm'
import { VibeForm } from '../VibeForm'
import { BrandDescriptionForm } from './BrandDescriptionForm'
import { BrandFinalPage } from './BrandFinalPage'
import { BrandUserForm } from './BrandUserForm'
import { ContentDescription } from './ContentDescription'
import { ProductServiceForm } from './ProductServiceForm'
import { TargetAudienceForm } from './TargetAudienceForm'

import { gql, useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  BrandDescriptionFormSchemaType,
  BrandUserFormSchemaType,
  ContentDescriptionSchemaType,
  ProductsServiceFormSchemaType,
  TargetAudienceFormSchemaType,
  ValuesFormSchemaType,
  VibeFormSchemaType,
  brandDescriptionFormSchema,
  brandUserFormSchema,
  contentDescriptionSchema,
  productsServiceFormSchema,
  targetAudienceFormSchema,
  valuesFormSchema,
  vibeSchema,
} from '@lib/types/zod-schema'
import { Check } from '@mui/icons-material'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import onboardingLogo from '../../../assets/onboarding-logo.png'

const MainContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  fontFamily: 'Arial',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  alignSelf: 'stretch',
  // mt: 'auto',
  // mb: 'auto',
}))

const StepperButton = styled(Button)(({ theme }) => ({
  mr: theme.spacing(1),
  px: theme.spacing(4),
}))

type FormData = BrandUserFormSchemaType &
  BrandDescriptionFormSchemaType &
  ProductsServiceFormSchemaType &
  TargetAudienceFormSchemaType &
  VibeFormSchemaType &
  ContentDescriptionSchemaType &
  ValuesFormSchemaType & {
    aestheticData: AestheticData
  }

type AestheticData = {
  Minimalist: number
  Maximalist: number
  Modern: number
  Traditional: number
  Boho: number
  Scandi: number
  Eclectic: number
  Simple: number
  New: number
  Vintage: number
  ReadyMade: number
  DIY: number
  BigBox: number
  SmallBusiness: number
  Aspirational: number
  Attainable: number
  Luxury: number
  BudgetFriendly: number
}

const INITIAL_DATA: FormData = {
  first_name: '',
  last_name: '',
  business_name: '',
  email: '',
  title: '',
  website: '',
  newsletter: false,
  marketingActivities: [],
  content: [],
  currentlyInAgency: false,
  inHouseContent: false,
  distMethod: [],
  productDescription: '',
  targetAudienceDescription: '',
  targetRegion: '',
  marketStrength: [],
  companyData: {
    companyType: '',
    companySize: '',
    industry: '',
    yearsInBusiness: 0,
    description: '',
  },
  aestheticData: {
    Minimalist: 2,
    Maximalist: 2,
    Modern: 2,
    Traditional: 2,
    Boho: 2,
    Scandi: 2,
    Eclectic: 2,
    Simple: 2,
    New: 2,
    Vintage: 2,
    ReadyMade: 2,
    DIY: 2,
    BigBox: 2,
    SmallBusiness: 2,
    Aspirational: 2,
    Attainable: 2,
    Luxury: 2,
    BudgetFriendly: 2,
  },
  vibe: [],
  vibeDesc: '',
  value: [],
  valueDesc: '',
}

const LOAD_DATA = gql`
  mutation LoadBrand($input: BrandData!) {
    loadBrand(input: $input) {
      message
      success
    }
  }
`

export default function BrandOnboarding() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const firstname = searchParams.get('firstname') || ''
  const lastname = searchParams.get('lastname') || ''
  const title = searchParams.get('title') || ''
  const email = searchParams.get('email') || ''
  const businessname = searchParams.get('business_name') || ''
  const website = searchParams.get('website') || ''
  const newsletter = searchParams.get('newsletter')
    ? searchParams.get('newsletter')?.toLowerCase() === 'true'
    : false

  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const [loadDataMutation] = useMutation(LOAD_DATA)
  const [final, setFinal] = useState(false)
  const [formData, setFormData] = useState(INITIAL_DATA)

  const vibeContentText = {
    header:
      'Share your brand vibe to help potential design partners get to know your brand better.',
    subheader:
      'Which option(s) best describes your brand and which do you want potential design partners to see on your profile?',
  }

  const valuesContentText = {
    header: 'How would you describe your brand values?',
    subheader:
      'Share your values to help potential design partners get to know you better.',
  }

  const onlinePresenceContentText = {
    header: `Describe the content you're looking for an influencer to create?`,
    subheader: `What types of content do you want to create with brand partners? (Choose all that apply)`,
  }

  const onboardingSteps = [
    'Register',
    'Your Brand',
    'Products/Services',
    'Target Audience',
    'Brand Style',
    'Marketing',
  ]

  const {
    control: controlBrandUserForm,
    handleSubmit: handleSubmitBrandUserForm,
  } = useForm<BrandUserFormSchemaType>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(brandUserFormSchema),
    defaultValues: {
      first_name: firstname,
      last_name: lastname,
      email: email,
      title: title,
      business_name: businessname,
      website: website,
      newsletter: newsletter,
    },
  })

  const {
    control: controlBrandDescriptionForm,
    handleSubmit: handleSubmitBrandDescriptionForm,
  } = useForm<BrandDescriptionFormSchemaType>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(brandDescriptionFormSchema),
    defaultValues: {
      companyData: { ...INITIAL_DATA.companyData },
    },
  })

  const {
    control: controlProductsServiceForm,
    handleSubmit: handleSubmitProductsServiceForm,
  } = useForm<ProductsServiceFormSchemaType>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(productsServiceFormSchema),
    defaultValues: {
      distMethod: [],
      productDescription: '',
    },
  })

  const {
    control: controlTargetAudienceForm,
    handleSubmit: handleSubmitTargetAudienceForm,
  } = useForm<TargetAudienceFormSchemaType>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(targetAudienceFormSchema),
    defaultValues: {
      targetAudienceDescription: '',
      targetRegion: '',
      marketStrength: [],
    },
  })

  const { control: controlVibesForm, handleSubmit: handleSubmitVibesForm } =
    useForm<VibeFormSchemaType>({
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      resolver: zodResolver(vibeSchema),
      defaultValues: {
        vibe: [],
        vibeDesc: '',
      },
    })

  const {
    control: controlContentDescriptionForm,
    handleSubmit: handleSubmitContentDescriptionForm,
  } = useForm<ContentDescriptionSchemaType>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(contentDescriptionSchema),
    defaultValues: {
      marketingActivities: [],
      content: [],
      currentlyInAgency: false,
      inHouseContent: false,
    },
  })

  // Value Form useForm
  const { control: controlValuesForm, handleSubmit: handleSubmitValuesForm } =
    useForm<ValuesFormSchemaType>({
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      resolver: zodResolver(valuesFormSchema),
      defaultValues: {
        value: [],
        valueDesc: '',
      },
    })

  function updateFields(fields: Partial<FormData>) {
    setFormData((prev: FormData) => {
      return { ...prev, ...fields }
    })
  }

  function onUpdateData<T extends FieldValues>(
    stepData: T,
  ): SubmitHandler<T> | void {
    console.log('stepData: ', stepData)
    setFormData((prev: FormData) => {
      return { ...prev, ...stepData }
    })

    return next()
  }

  const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } =
    useMultistepForm([
      <BrandUserForm control={controlBrandUserForm} />,
      <OnboardingNotes
        first_name={formData.first_name}
        steps={onboardingSteps}
      />,
      <BrandDescriptionForm control={controlBrandDescriptionForm} />,
      <ProductServiceForm control={controlProductsServiceForm} />,
      <TargetAudienceForm control={controlTargetAudienceForm} />,
      <AestheticForm {...formData} updateFields={updateFields} />,
      <VibeForm control={controlVibesForm} contentText={vibeContentText} />,
      <ValuesForm
        control={controlValuesForm}
        contentText={valuesContentText}
      />,
      <ContentDescription control={controlContentDescriptionForm} />,
    ])

  useEffect(() => {
    async function submitData() {
      try {
        console.log('submitting data...')
        setLoading(true)
        const { data } = await loadDataMutation({
          variables: {
            input: formData,
          },
        })
        if (data && data?.loadBrand) {
          const { message, success } = data.loadBrand
          console.log(`Message: ${message}, Success: ${success}`)
          if (success) {
            setFinal(true)
            console.log('Data submitted successfully.')
          }
        } else {
          console.error('Unexpected response format:', data)
        }
      } catch (error) {
        console.error('Error executing LOAD_DATA mutation:', error)
      } finally {
        setLoading(false)
      }
    }

    if (
      done &&
      formData.marketingActivities.length > 0 &&
      formData.content.length > 0 &&
      isLastStep
    ) {
      submitData()
    }
  }, [done, formData, loadDataMutation])

  function onSubmit(e: FormEvent) {
    e.preventDefault()
    e.stopPropagation()

    switch (currentStepIndex) {
      case 0:
        void handleSubmitBrandUserForm(onUpdateData)(e)
        break
      case 2:
        void handleSubmitBrandDescriptionForm(onUpdateData)(e)
        break
      case 3:
        void handleSubmitProductsServiceForm(onUpdateData)(e)
        break
      case 4:
        void handleSubmitTargetAudienceForm(onUpdateData)(e)
        break
      case 6:
        void handleSubmitVibesForm(onUpdateData)(e)
        break
      case 7:
        void handleSubmitValuesForm(onUpdateData)(e)
        break
      case 8:
        void handleSubmitContentDescriptionForm(onUpdateData)(e)
        setDone(true)
        break
      default:
        return next()
    }

  }

  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean }
  }>(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundColor: theme.palette.secondary.main,
    }),
  }))

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {completed ? <Check /> : <span>{props.icon}</span>}
      </ColorlibStepIconRoot>
    )
  }

  return final === true ? (
    <BrandFinalPage
      name={`${formData.first_name} ${formData.last_name}`}
      businessName={formData.business_name}
      email={formData.email}
    />
  ) : (
    <MainContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'stretch',
          gap: 1,
        }}
      >
        <Stepper activeStep={currentStepIndex} alternativeLabel>
          {steps.map((steps, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'stretch',
            m: 2,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={onboardingLogo} alt="logo" style={{ width: '200px' }} />
        </Box>

        <Box
          sx={{
            flexBasis: '100%',
            mx: 'auto',
            width: '100%',
            alignSelf: 'stretch',
          }}
        >
          <form noValidate onSubmit={onSubmit}>
            <Box>{step}</Box>
            <div
              style={{
                marginTop: '1rem',
                display: 'flex',
                gap: '.5rem',
                justifyContent: 'center',
              }}
            >
              {!isFirstStep && (
                <StepperButton
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={back}
                >
                  Back
                </StepperButton>
              )}
              <StepperButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                {loading ? 'Loading...' : isLastStep ? 'Submit' : 'Next'}
              </StepperButton>
            </div>
          </form>
        </Box>
      </Box>
    </MainContainer>
  )
}
