import { CampaignStylesSchemaType } from '@lib/types/campaign-builder-zod'
import {
  Box,
  Stack,
  Typography,
  Slider,
} from '@mui/material'
import { Control, Controller } from 'react-hook-form'

type CampaignStylesFormProps = {
  control: Control<CampaignStylesSchemaType, any>
}

const style = {
  form: {
    mb: 2,
  },
  alignment: {
    direction: 'column',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flex: 0.2,
    p: 2,
    gap: 2,
    width: '80%',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sub_alignment: {
    mt: 6,
    p: 2,
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}

const STYLE_LABELS = [
  {
    keys: ['minimalist', 'maximalist'],
    labels: ['Minimalist', 'Maximalist'],
    propName: 'campaignStyles.minimalistMaximalist',
  },
  {
    keys: ['modern', 'traditional'],
    labels: ['Modern', 'Traditional'],
    propName: 'campaignStyles.modernTraditional',
  },
  {
    keys: ['new', 'vintage'],
    labels: ['New', 'Vintage'],
    propName: 'campaignStyles.newVintage',
  },
  {
    keys: ['aspirational', 'attainable'],
    labels: ['Aspirational', 'Attainable'],
    propName: 'campaignStyles.aspirationalAttainable',
  },
  {
    keys: ['bigbox', 'smallbusiness'],
    labels: ['Big Box', 'Small Business'],
    propName: 'campaignStyles.bigboxSmallBusiness',
  },
  {
    keys: ['boho', 'scandinavian'],
    labels: ['Boho', 'Scandinavian'],
    propName: 'campaignStyles.bohoScandinavian',
  },
  {
    keys: ['eclectic', 'simple'],
    labels: ['Eclectic', 'Simple'],
    propName: 'campaignStyles.eclecticSimple',
  },
  {
    keys: ['luxury', 'budget'],
    labels: ['Luxury', 'Budget'],
    propName: 'campaignStyles.luxuryBudget',
  },
  {
    keys: ['readymade', 'diy'],
    labels: ['Ready Made', 'DIY'],
    propName: 'campaignStyles.readymadeDIY',
  },
]

export function CampaignStylesForm({ control }: CampaignStylesFormProps) {
  return (
    <>
      <Stack sx={style.alignment}>
        <Box sx={style.header}>
          <Typography variant="h4" component={'h2'} textAlign={'center'}>
            Do you have specific style goals for this campaign?
          </Typography>
          {STYLE_LABELS.map((item, index) => (
            <Controller
              name={item.propName as any}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Box sx={{ m: 2 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography id="input-slider" gutterBottom>
                      {item.labels[0]}
                    </Typography>
                    <Typography id="input-slider" gutterBottom>
                      {item.labels[1]}
                    </Typography>
                  </Box>
                  <Stack spacing={1}>
                    <Slider
                      value={Number(value[item.keys[1]])}
                      onChange={(e: any) => {
                        console.log(e.target.value)
                        const newValue = Number(e.target.value)
                        onChange({
                          [item.keys[1]]: newValue,
                          [item.keys[0]]: 4 - newValue,
                        })
                      }}
                      step={1}
                      marks
                      min={0}
                      max={4}
                    />
                  </Stack>
                </Box>
              )}
            />
          ))}
        </Box>
      </Stack>
    </>
  )
}
