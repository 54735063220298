import { CampaignConceptsSchemaType } from '@lib/types/campaign-builder-zod'
import { Autocomplete, Box, FormHelperText, Stack, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { Control, Controller } from 'react-hook-form'
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React from 'react';

type CampaignConceptsFormProps = {
  control: Control<CampaignConceptsSchemaType, any>
}

const style = {
  form: {
    mb: 2,
    minWidth: 300,
  },
  alignment: {
    direction: 'column',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flex: 0.2,
    p: 2,
    gap: 2,
    width: '80%',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sub_alignment: {
    mt: 6,
    p: 2,
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}

export function CampaignConceptsForm({ control }: CampaignConceptsFormProps) {
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack sx={style.alignment}>
          <Box sx={style.header}>
            <Typography variant="h4" component={'h2'} textAlign={'center'}>
              Do you have a campaign concept in mind?
            </Typography>
          </Box>
          <Controller
            name="campaignConceptsDescription"
            control={control}
            render={({
              field: { value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                onChange={(e) => onChange(e.target.value)}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                helperText={error ? error.message : null}
                type="text"
                label="Campaign Concept Description"
                id="campaignConceptsDescription"
                variant="outlined"
                size="small"
                multiline
                rows={4}
                sx={style.form}
              />
            )}
          />
          <Box sx={style.header}>
            <Typography variant="h4" component={'h2'} textAlign={'center'}>
              What's your planned campaign timeline?
            </Typography>
          </Box>
          <Stack direction="row" spacing={2}>
            <Controller
              name="timelineStartDate"
              control={control}
              render={({
                field: { value, onChange, onBlur, ref },
                fieldState: { error },
              }) => (
                  <DatePicker
                    onChange={(e) => {
                      // startDate must be before endDate
                      const endDate = dayjs(control._formValues.timelineEndDate)
                      const startDate = dayjs(e)
                      if (endDate.isBefore(startDate)) {
                        setErrorMessage('Start date must be before end date')
                      } else {
                        setErrorMessage('')
                      }
                      onChange(e?.toISOString())
                    }}
                    value={dayjs(value)}
                    inputRef={ref}
                    label="Start Date"
                  />
              )}
            />
            <Controller
              name="timelineEndDate"
              control={control}
              render={({
                field: { value, onChange, onBlur, ref },
                fieldState: { error },
              }) => (
                  <DatePicker
                    onChange={(e) => {
                      // endDate must be after startDate
                      const startDate = dayjs(control._formValues.timelineStartDate)
                      const endDate = dayjs(e)
                      if (endDate.isBefore(startDate)) {
                        setErrorMessage('End date must be after start date')
                      } else {
                        setErrorMessage('')
                      }
                      onChange(e?.toISOString())
                    }}
                    value={dayjs(value)}
                    inputRef={ref}
                    label="End Date"
                  />
              )}
            />
          </Stack>
          <FormHelperText 
            error={true}
            sx={{ textAlign: 'center' }}
            variant='standard'
            hidden={errorMessage === ''}
          >
            {errorMessage}
          </FormHelperText>
        </Stack>
      </LocalizationProvider>
    </>
  )
}
