// Create a simple Lorem Ipsum page

import { CampaignAudienceSchemaType } from '@lib/types/campaign-builder-zod'
import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material'
import { Control, Controller } from 'react-hook-form'

type CampaignAudienceFormProps = {
  control: Control<CampaignAudienceSchemaType, any>
}

const style = {
  form: {
    mb: 2,
    minWidth: 300,
  },
  alignment: {
    direction: 'column',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flex: 0.2,
    p: 2,
    gap: 2,
    width: '80%',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sub_alignment: {
    mt: 6,
    p: 2,
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}

const SHOWCASE_LOCATIONS = [
  'kitchen',
  'dining',
  'room',
  'mudroom',
  'laundry',
  'living',
  'room',
  'bathroom',
  'bedroom',
  'nursery',
  'office',
  'closet',
  'basement',
  'backyard',
  'front',
  'yard',
  'other',
]

export function CampaignAudienceForm({ control }: CampaignAudienceFormProps) {
  const showcaseLocations = SHOWCASE_LOCATIONS

  return (
    <>
      <Stack sx={style.alignment}>
        <Box sx={style.header}>
          <Typography variant="h4" component={'h2'} textAlign={'center'}>
            Do you have specific spaces in mind for showcasing your product(s)?
          </Typography>
        </Box>
        <Controller
          name="campaignShowcaseLocation"
          control={control}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => (
            <Autocomplete
              id="campaignShowcaseLocation"
              onChange={(e, selectedValue) => {
                onChange(selectedValue)
              }}
              multiple
              onBlur={onBlur}
              value={value}
              options={showcaseLocations}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Select Showcase Locations"
                  error={Boolean(error)}
                  sx={style.form}
                  helperText={error ? error.message : null}
                />
              )}
            />
          )}
        />
        <Box sx={style.header}>
          <Typography variant="h4" component={'h2'} textAlign={'center'}>
            Lastly, tell us about your target audience.
          </Typography>
        </Box>
        <Controller
          name="audienceDescription"
          control={control}
          render={({
            field: { value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <TextField
              required
              error={!!error}
              onChange={(e) => onChange(e.target.value)}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              helperText={error ? error.message : null}
              type="text"
              label="Audience Description"
              placeholder='e.g. "Homeowners in the US who are looking to renovate their kitchen"'
              id="audienceDescription"
              variant="outlined"
              size="small"
              multiline
              rows={4}
              sx={style.form}
            />
          )}
        />
      </Stack>
    </>
  )
}
