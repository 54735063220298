// Create a simple Lorem Ipsum page
import { Box, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material'
import React from 'react'

const style = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: 3,
    width: '100%',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.8,
    gap: 1,
    textAlign: 'center',
    width: '100%',
    mt: 'auto',
    mb: 'auto',
    p: 2,
  },
  stepper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.2,
  },
}

interface OnboardingNotesProps {
  first_name: string
  steps: string[]
}

const OnboardingNotes: React.FC<OnboardingNotesProps> = ({
  first_name,
  steps,
}) => {
  const [activeStep, setActiveStep] = React.useState(1)
  const l = 'asdfasdf'

  return (
    <Stack sx={style.root}>
      <Box sx={style.text}>
        <Typography variant="body1" component="p">
          A quick note
        </Typography>

        <Typography variant="h5" component="h2">
          It's great to meet you, {first_name}!
        </Typography>

        <Typography variant="body2" component="p">
          Your submissions will help us to create your ultracollective profile
          so you can get the attention of potential brand partners.
        </Typography>
        <Typography variant="body2" component="p">
          Be sure to provide detailed answers as that will assist our
          one-of-a-kind algorithm in matching you with brands and marketing
          campaigns that best fit your aesthetic, values, and goals.
        </Typography>
      </Box>

      <Box sx={style.stepper}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Stack>
  )
}

export default OnboardingNotes
