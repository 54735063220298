import {
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
  Box,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { ServiceDescriptionFormSchemaType } from '@lib/types/zod-schema'
import { Control, Controller } from 'react-hook-form'
import { useEffect, useState } from 'react'

type ServiceDescriptionFormProps = {
  control: Control<ServiceDescriptionFormSchemaType, any>
}
// type ServiceData = {
//   companyData: CompanyData
// }

// type CompanyData = {
//   companyType: string
//   category: string
//   description: string
//   companyName: string
// }

// type UserFormProps = ServiceData & {
//   updateFields: (fields: Partial<ServiceData>) => void
// }

const style = {
  form: {
    mb: 2,
    width: 300,
  },
  alignment: {
    direction: 'column',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flex: 0.2,
    p: 2,
    width: '80%',
    justifyContent: 'center',
  },
}

export function ServiceDescForm(control: ServiceDescriptionFormProps) {
  const companyTypes = [
    'Independent Designer',
    'Small Firm (under 10)',
    'Medium Firm (10-50)',
    'Large Firm (50+)',
  ]
  const categories = [
    'Residential',
    'Commercial',
    'Hospitality',
    'Retail',
    'Other',
  ]
  useEffect(() => {
    if (control.control._formValues.companyData.companyType) {
      setSelectedCompanyType(
        control.control._formValues.companyData.companyType,
      )
    }
  }, [control.control._formValues.companyData.companyType])
  const [selectedCompanyType, setSelectedCompanyType] = useState('')
  return (
    <>
      <Stack sx={style.alignment}>
        <Box sx={style.header}>
          <Typography p={2} fontSize={30} textAlign={'center'}>
            How would you describe your services?
          </Typography>
        </Box>
        <Controller
          name="companyData.companyType"
          control={control.control}
          render={({
            field: { value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <Autocomplete
              id="companyType"
              onChange={(e, selectedValue) => {
                onChange(selectedValue)
                setSelectedCompanyType(selectedValue ?? '')
              }}
              onBlur={onBlur}
              value={value}
              options={companyTypes}
              sx={style.form}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Your Company"
                  error={Boolean(error)}
                  helperText={error ? error.message : null}
                />
              )}
            />
          )}
        />
        {[
          'Small Firm (under 10)',
          'Medium Firm (10-50)',
          'Large Firm (50+)',
        ].includes(selectedCompanyType) && (
          <Controller
            name="companyData.companyName"
            control={control.control}
            render={({
              field: { value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                helperText={error ? error.message : null}
                type="text"
                label="Company Name"
                variant="outlined"
                sx={style.form}
              />
            )}
          />
        )}

        <Controller
          name="companyData.category"
          control={control.control}
          render={({
            field: { value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <Autocomplete
              id="category"
              onChange={(e, selectedValue) => {
                onChange(selectedValue)
              }}
              onBlur={onBlur}
              value={value}
              options={categories}
              sx={style.form}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  error={Boolean(error)}
                  helperText={error ? error.message : null}
                />
              )}
            />
          )}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            width={300}
            mb={1}
            mt={2}
          >
            What services do you offer that you want potential brand partners to
            see on your profile? Give brief descriptions of each.
          </Typography>
          <Controller
            name="companyData.description"
            control={control.control}
            render={({
              field: { value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                multiline
                rows={4}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                helperText={error ? error.message : null}
                type="text"
                label="Service Description"
                variant="outlined"
                sx={style.form}
              />
            )}
          />
        </Box>

        {/* <Autocomplete
          id="companyType"
          value={companyData.companyType}
          onChange={(e, value) => {
            if (value) {
              updateFields({
                companyData: { ...companyData, companyType: value },
              })
            }
          }}
          options={companyTypes}
          sx={style.form}
          renderInput={(params) => (
            <TextField {...params} label="Your Company" />
          )}
        />
        {[
          'Small Firm (under 10)',
          'Medium Firm (10-50)',
          'Large Firm (50+)',
        ].includes(companyData.companyType) && (
          <TextField */}
        {/* //  error={nameError && nameError.length > 0 && !name ? true : false}
            //  helperText={
            //    nameError && nameError.length > 0 && !name ? nameError : ''
            //  }
            type="text"
            label="Company Name"
            id="companyName"
            variant="outlined"
            required
            sx={{ mb: 2, width: 300 }}
            value={companyData.companyName}
            onChange={(e) =>
              updateFields({
                companyData: { ...companyData, companyName: e.target.value },
              })
            }
          />
        )}

        <Autocomplete
          id="category"
          value={companyData.category}
          onChange={(e, value) => {
            if (value) {
              updateFields({
                companyData: { ...companyData, category: value },
              })
            }
          }}
          options={categories}
          sx={style.form}
          renderInput={(params) => (
            <TextField {...params} label="Select Client Category" />
          )}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            width={300}
            mb={1}
            mt={2}
          >
            What services do you offer that you want potential brand partners to
            see on your profile? Give brief descriptions of each.
          </Typography>
          <TextField
            id="serviceDescription"
            label="Service Description"
            multiline
            rows={4}
            variant="outlined"
            value={companyData.description}
            onChange={(e) =>
              updateFields({
                companyData: { ...companyData, description: e.target.value },
              })
            }
            fullWidth
            sx={{ mb: 2, width: 300 }}
          />
        </Box> */}
      </Stack>
    </>
  )
}
