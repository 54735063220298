// Create a simple Lorem Ipsum page

import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Stack,
  Button,
} from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface BrandFinalPageProps {
  name: string
  businessName: string
  email: string
}

const style = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: 3,
    width: '100%',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.8,
    gap: 1,
    textAlign: 'center',
    width: '100%',
    mt: 'auto',
    mb: 'auto',
    p: 2,
  },
  stepper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.2,
  },
}

export const BrandFinalPage = ({ name, email, businessName }: BrandFinalPageProps) => {
  const l = 'asdfasdf'
  const steps = [
    'Register',
    'Your Brand',
    'Products/Services',
    'Target Audience',
    'Brand Style',
    'Marketing',
  ]
  const [activeStep, setActiveStep] = React.useState(steps.length)
  const brandData = {
    name: name,
    businessName: businessName,
    email: email,
  }

  const navigate = useNavigate()

  const handleStartCampaign = () => {
    // Navigate to the target route with data as state
    navigate('/brands/campaigns/builder', { state: { brandData } })
  }
  return (
    <Stack sx={style.root}>
      <Box sx={style.text}>
        <Typography variant="h5" component="h2">
          Great! You did it!
        </Typography>

        <Typography variant="h3" component="p" sx={{ mb: 4 }}>
          Let's start your first campaign
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={handleStartCampaign}
          >
            Start Campaign
          </Button>
        </Box>
      </Box>

      <Box sx={style.stepper}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Stack>
  )
}
