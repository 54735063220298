import { Box, Container, responsiveFontSizes } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CampaignBuilder from '@pages/onboarding/campaign-builder/CampaignBuilder';
import StartCampaignBuilder from '@pages/onboarding/campaign-builder/StartCampaignBuilder';
import BrandOnboardingForm from '@pages/onboarding/brand-onboarding/BrandOnboarding'
import useMarkerio from '@lib/hooks/useMarkerio'
import './App.css'
import CampaignRecommendations from '@pages/onboarding/campaign-builder/CampaignRecommendations';
import DesignerOnboarding from '@pages/onboarding/designer-onboarding/DesignerOnboarding';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import CampaignPlan from '@pages/onboarding/campaign-builder/CampaignPlan';

let theme = createTheme({
  palette: {
    common: {
      black: '#201442',
      white: '#55FCFF',
    },
    primary: {
      main: '#EB3F86',
    },
    secondary: {
      main: '#1E1E54',
    },
  },
  typography: {
    fontFamily: ['"Mulish"', '"Montserrat"', 'Arial'].join(','),
    h1: {
      fontWeight: 700,
      fontSize: '3rem',
    },
    h2: {
      fontWeight: 700,
      fontSize: '2.5rem',
    },
    h3: {
      fontWeight: 700,
      fontSize: '2rem',
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.5rem',
    },
    h5: {
      fontWeight: 700,
      fontSize: '1.25rem',
    },
    h6: {
      fontWeight: 700,
      fontSize: '1rem',
    },
    subtitle1: {
      font: '"Montserrat"',
      fontWeight: 400,
      fontSize: '1.25rem',
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '0.875rem',
    },
    body1: {
      fontWeight: 400,
      fontSize: '1rem',
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.875rem',
    },
    button: {
      fontWeight: 700,
      fontSize: '1rem',
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.75rem',
    },
    overline: {
      fontWeight: 400,
      fontSize: '0.75rem',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#201442',
          color: '#55FCFF',
        },
      },
    },
  },
})
theme = responsiveFontSizes(theme)

export default function App() {
  useMarkerio()

  return (
    <ThemeProvider theme={theme}>
      <Container
        sx={{ height: '100%', py: 4, my: 4 }}
        maxWidth="sm"
        component="main"
      >
        <Box
          sx={{
            height: '100%',
            px: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Router>
            <Routes>
              <Route path="/designers" Component={DesignerOnboarding} />
              <Route path="/brands" Component={BrandOnboardingForm} />
              <Route path="/brands/campaigns/start" Component={StartCampaignBuilder} />
              <Route path="/brands/campaigns/builder" Component={CampaignBuilder} />
              <Route path="/brands/campaigns/recommendation" Component={CampaignRecommendations} />
              <Route path="/brands/campaigns/:plan_key" Component={CampaignPlan} />
            </Routes>
          </Router>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
