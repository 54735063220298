import { z } from 'zod'

const looseOptional = <T extends z.ZodTypeAny>(schema: T) =>
  z.preprocess(
    (value: unknown) =>
      value === null || (typeof value === 'string' && value === '')
        ? undefined
        : value,
    schema.optional(),
  )

const socialFieldUnion = z.union([
  z.object({
    url: z
      .string()
      .min(1, { message: 'Please enter a valid URL' })
      .url({ message: 'Please enter a valid URL' })
      .default(''),
    followers: z.number().int().min(1).gte(1),
  }),
  z.object({
    url: looseOptional(z.string().min(0).default('')),
    followers: looseOptional(z.number().int().min(0).lte(0).gte(0).nullish()),
  }),
])

export const userFormSchema = z.object({
  first_name: z
    .string()
    .nonempty({ message: 'Please enter your first name' })
    .max(100, { message: 'First name must be less than 100 characters' })
    .default(''),
  last_name: z
    .string()
    .nonempty({ message: 'Please enter your last name' })
    .max(100, { message: 'Last name must be less than 100 characters' })
    .default(''),
  business_name: looseOptional(
    z
      .string()
      .max(100, { message: 'Name must be less than 100 characters' })
      .default(''),
  ),
  email: z
    .string()
    .email({ message: 'Please enter a valid email' })
    .max(100, { message: 'Email must be less than 100 characters' })
    .nonempty({ message: 'Please enter your email' })
    .default(''),
  website: looseOptional(
    z.string().url({ message: 'Please enter a valid URL' }).default(''),
  ),
  newsletter: z.boolean().default(false),
})

export type UserFormSchemaType = z.infer<typeof userFormSchema>

const socialFieldsSchema = z.object({
  instagram: socialFieldUnion,
  facebook: socialFieldUnion,
  twitter: socialFieldUnion,
  linkedin: socialFieldUnion,
  youtube: socialFieldUnion,
  tiktok: socialFieldUnion,
  pinterest: socialFieldUnion,
})

export const socialFormSchema = z.object({
  socials: socialFieldsSchema,
})

export type SocialFieldsSchemaType = z.infer<typeof socialFieldsSchema>
export type SocialFormSchemaType = z.infer<typeof socialFormSchema>

const serviceDescriptionSchema = z.object({
  companyType: z.string().nonempty({ message: 'Please select a company type' }),
  category: z.string().nonempty({ message: 'Please select a category' }),
  companyName: looseOptional(
    z
      .string()
      .nonempty({ message: 'Please enter a company name' })
      .max(100, { message: 'Company name must be less than 100 characters' }),
  ),
  description: z
    .string()
    .nonempty({ message: 'Please enter a brief description' })
    .max(240, { message: 'Description must be less than 240 characters' }),
})

export const serviceDescriptionFormSchema = z.object({
  companyData: serviceDescriptionSchema,
})

export type ServiceDescriptionSchemaType = z.infer<
  typeof serviceDescriptionSchema
>
export type ServiceDescriptionFormSchemaType = z.infer<
  typeof serviceDescriptionFormSchema
>
export const marketingExperienceSchema = z.object({
  yearsExperience: z.number().int().min(1).gte(0).lte(100).default(0),
  marketingActivities: z.string().array().min(1).max(10).default([]),
  content: z
    .string()
    .array()
    .min(1, 'Select atleast one item')
    .max(10)
    .default([]),
  currentlyInAgency: z.boolean().default(false),
  inHouseContent: z.boolean().default(false),
})

export type MarketingExperienceSchemaType = z.infer<
  typeof marketingExperienceSchema
>

export const vibeSchema = z.object({
  vibe: z
    .string()
    .array()
    .min(1, 'Select atleast one the fits your Vibe')
    .max(10)
    .default([]),
  vibeDesc: z
    .string()
    .max(240, { message: 'Description must be less than 240 characters' }),
})
export type VibeFormSchemaType = z.infer<typeof vibeSchema>

export const inclusionExclusionSchema = z.object({
  inclusionDesc: z
    .string()
    .nonempty({ message: 'Please enter a brief description' })
    .max(240, { message: 'Description must be less than 240 characters' }),
  exclusionDesc: z
    .string()
    .nonempty({ message: 'Please enter a brief description' })
    .max(240, { message: 'Description must be less than 240 characters' }),
})
export type InclusionExclusionFormSchemaType = z.infer<
  typeof inclusionExclusionSchema
>
export const valuesFormSchema = z.object({
  value: z
    .string()
    .array()
    .min(1, 'Select atleast one item')
    .max(10)
    .default([]),
  valueDesc: z
    .string()
    .max(240, { message: 'Description must be less than 240 characters' }),
})

export type ValuesFormSchemaType = z.infer<typeof valuesFormSchema>

export const brandUserFormSchema = z.object({
  first_name: z
    .string()
    .nonempty({ message: 'Please enter your first name' })
    .max(100, { message: 'First name must be less than 100 characters' })
    .default(''),
  last_name: z
    .string()
    .nonempty({ message: 'Please enter your last name' })
    .max(100, { message: 'Last name must be less than 100 characters' })
    .default(''),
  title: z
    .string()
    .nonempty({ message: 'Please enter your title' })
    .max(100, { message: 'Last name must be less than 100 characters' })
    .default(''),
  email: z
    .string()
    .email({ message: 'Please enter a valid email' })
    .max(100, { message: 'Email must be less than 100 characters' })
    .nonempty({ message: 'Please enter your email' })
    .default(''),
  business_name: z
    .string()
    .nonempty({ message: 'Please enter your business name' })
    .max(100, { message: 'Name must be less than 100 characters' })
    .default(''),
  website: looseOptional(
    z.string().url({ message: 'Please enter a valid URL' }).default(''),
  ),
  newsletter: z.boolean().default(false),
})

export type BrandUserFormSchemaType = z.infer<typeof brandUserFormSchema>

const brandDescriptionSchema = z.object({
  companyType: looseOptional(
    z.string().nonempty({ message: 'Please select a company type' }),
  ),
  companySize: looseOptional(
    z.string().nonempty({ message: 'Please select a company size' }),
  ),
  industry: looseOptional(
    z.string().nonempty({ message: 'Please select an industry' }),
  ),
  yearsInBusiness: looseOptional(z.number().int().gte(0).lte(100).default(0)),
  description: looseOptional(
    z
      .string()
      .max(240, { message: 'Description must be less than 240 characters' }),
  ),
})

export const brandDescriptionFormSchema = z.object({
  companyData: brandDescriptionSchema,
})

export type BrandDescriptionSchemaType = z.infer<typeof brandDescriptionSchema>
export type BrandDescriptionFormSchemaType = z.infer<
  typeof brandDescriptionFormSchema
>

export const productsServiceFormSchema = z.object({
  distMethod: z.string().array().max(10).default([]),
  productDescription: z.string().max(240, {
    message: 'Description must be less than 240 characters',
  }),
})

export type ProductsServiceFormSchemaType = z.infer<
  typeof productsServiceFormSchema
>

export const targetAudienceFormSchema = z.object({
  targetAudienceDescription: z
    .string()
    .nonempty({ message: 'Please enter a brief description' })
    .max(240, {
      message: 'Description must be less than 240 characters',
    }),
  targetRegion: looseOptional(
    z.string().max(240, {
      message: 'Description must be less than 240 characters',
    }),
  ),
  marketStrength: z
    .string()
    .array() /*  */
    .max(10)
    .default([]),
})

export type TargetAudienceFormSchemaType = z.infer<
  typeof targetAudienceFormSchema
>

export const contentDescriptionSchema = z.object({
  marketingActivities: z.string().array().min(1).max(10).default([]),
  content: z
    .string()
    .array()
    .min(1, 'Select atleast one item')
    .max(10)
    .default([]),
  currentlyInAgency: z.boolean().default(false),
  inHouseContent: z.boolean().default(false),
})

export type ContentDescriptionSchemaType = z.infer<
  typeof contentDescriptionSchema
>
