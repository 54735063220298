import {
  Box,
  Button,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
} from '@mui/material'
import { FormEvent, useEffect, useState } from 'react'

import { useMultistepForm } from '../../../lib/hooks/useMultistepForm'
import { FinalForm } from '../Final'

import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  GiftingTypeSchemaType,
  giftingTypeSchema,
  CampaignProductListSchemaType,
  campaignProductListSchema,
  campaignGoalsSchema,
  CampaignGoalsSchemaType,
  CampaignConceptsSchemaType,
  campaignConceptsSchema,
  CampaignStylesSchemaType,
  campaignStylesSchema,
  CampaignAudienceSchemaType,
  campaignAudienceSchema,
  SelectedDesignersSchemaType,
  selectedDesignersSchema,
} from '@lib/types/campaign-builder-zod'
import { Check } from '@mui/icons-material'
import { AboutForm } from './AboutForm'
import { GiftingTypeForm } from './GiftingTypeForm'
import { CampaignProductForm } from './CampaignProductForm'
import { CampaignGoalsForm } from './CampaignGoalsForm'
import { CampaignConceptsForm } from './CampaignConceptsForm'
import { CampaignStylesForm } from './CampaignStylesForm'
import { CampaignAudienceForm } from './CampaignAudienceForm'
import { DesignerRecommendationForm } from './DesignerRecommendationForm'
import { useLocation, useNavigate } from 'react-router-dom'

const MainContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  fontFamily: 'Arial',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  alignSelf: 'stretch',
  // mt: 'auto',
  // mb: 'auto',
}))

const StepperButton = styled(Button)(({ theme }) => ({
  mr: theme.spacing(1),
  px: theme.spacing(4),
}))

// const TEMP_BRAND_DATA = {
//   name: 'Jhake Inson',
//   email: 'admin@fungames.com',
//   businessName: 'Fun Gamez',
// }

export default function CampaignBuilder() {
  const location = useLocation()
  const {brandData} = location.state || {}
  // const brandData = TEMP_BRAND_DATA
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const [formData, setFormData] = useState({})
  const [final, setFinal] = useState(false)
  const navigate = useNavigate()

  // Gifting Type Form
  const {
    control: giftingTypeForm,
    handleSubmit: handleGiftingTypeForm,
    setValue: setValueGiftingTypeForm,
  } = useForm<GiftingTypeSchemaType>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(giftingTypeSchema),
    defaultValues: {
      giftingType: 'monetary',
      totalProductQuantity: 0,
      totalProductValue: 0.0,
      totalMonetaryValue: 0,
    },
  })

  // Campaign Product Form
  const {
    control: campaignProductForm,
    handleSubmit: handleCampaignProductForm,
    setValue: setValueCampaignProductForm,
  } = useForm<CampaignProductListSchemaType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(campaignProductListSchema),
    defaultValues: {
      products: [
        {
          productName: '',
          productDescription: '',
        },
      ],
    },
  })

  // Campaign Goals Form
  const { control: campaignGoalsForm, handleSubmit: handleCampaignGoalsForm } =
    useForm<CampaignGoalsSchemaType>({
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      resolver: zodResolver(campaignGoalsSchema),
      defaultValues: {
        campaignGoals: [],
        kpis: '',
      },
    })

  // Campaign Concepts Form
  const {
    control: campaignConceptsForm,
    handleSubmit: handleCampaignConceptsForm,
  } = useForm<CampaignConceptsSchemaType>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(campaignConceptsSchema),
    defaultValues: {
      campaignConceptsDescription: '',
      timelineStartDate: new Date().toISOString(),
      timelineEndDate: '',
    },
  })

  // Campaign Styles Form
  const {
    control: campaignStylesForm,
    handleSubmit: handleCampaignStylesForm,
  } = useForm<CampaignStylesSchemaType>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(campaignStylesSchema),
    defaultValues: {
      campaignStyles: {
        minimalistMaximalist: {
          minimalist: 2,
          maximalist: 2,
        },
        modernTraditional: {
          modern: 2,
          traditional: 2,
        },
        newVintage: {
          new: 2,
          vintage: 2,
        },
        aspirationalAttainable: {
          aspirational: 2,
          attainable: 2,
        },
        bigboxSmallBusiness: {
          bigbox: 2,
          smallbusiness: 2,
        },
        bohoScandinavian: {
          boho: 2,
          scandinavian: 2,
        },
        eclecticSimple: {
          eclectic: 2,
          simple: 2,
        },
        luxuryBudget: {
          luxury: 2,
          budget: 2,
        },
        readymadeDIY: {
          readymade: 2,
          diy: 2,
        },
      },
    },
  })

  // Campaign Audience Form
  const {
    control: campaignAudienceForm,
    handleSubmit: handleCampaignAudienceForm,
  } = useForm<CampaignAudienceSchemaType>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(campaignAudienceSchema),
    defaultValues: {
      campaignShowcaseLocation: [],
      audienceDescription: '',
    },
  })

  // Selected Designers Form
  const {
    control: selectedDesignersForm,
    handleSubmit: handleSelectedDesignersForm,
  } = useForm<SelectedDesignersSchemaType>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(selectedDesignersSchema),
    defaultValues: {
      selectedDesigners: [],
    },
  })

  function onUpdateData<T extends FieldValues>(
    stepData: T,
  ): SubmitHandler<T> | void {
    console.log('stepData: ', stepData)
    setFormData((prev: FormData) => {
      return { ...prev, ...stepData }
    })

    return next()
  }

  const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } =
    useMultistepForm([
      <DesignerRecommendationForm
        control={selectedDesignersForm}
        brandData={ brandData || {}}
      />,
      <CampaignGoalsForm control={campaignGoalsForm} />,
      <CampaignConceptsForm control={campaignConceptsForm} />,
      <GiftingTypeForm
        setValue={setValueGiftingTypeForm}
        control={giftingTypeForm}
      />,
      <CampaignProductForm
        setValue={setValueCampaignProductForm}
        control={campaignProductForm} 
      />,
      <CampaignStylesForm control={campaignStylesForm} />,
      <CampaignAudienceForm control={campaignAudienceForm} />,
      <AboutForm />,
    ])

  useEffect(() => {
    const handleRedirect = () => {
      navigate('/brands/campaigns/recommendation', { state: { formData, brandData } })
    }

    if (done) {
      handleRedirect()
    }
  }, [ brandData, done, formData, navigate])

  function onSubmit(e: FormEvent) {
    e.preventDefault()
    e.stopPropagation()

    switch (currentStepIndex) {
      case 0:
        void handleSelectedDesignersForm(onUpdateData)(e)
        break
      case 1:
        void handleCampaignGoalsForm(onUpdateData)(e)
        break
      case 2:
        void handleCampaignConceptsForm(onUpdateData)(e)
        break
      case 3:
        void handleGiftingTypeForm(onUpdateData)(e)
        break
      case 4:
        void handleCampaignProductForm(onUpdateData)(e)
        break
      case 5:
        void handleCampaignStylesForm(onUpdateData)(e)
        break
      case 6:
        void handleCampaignAudienceForm(onUpdateData)(e)
        break
      case 7:
        setDone(true)
        break
      default:
        return next()
    }
  }

  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean }
  }>(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundColor: theme.palette.secondary.main,
    }),
  }))

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {completed ? <Check /> : <span>{props.icon}</span>}
      </ColorlibStepIconRoot>
    )
  }

  return final === true ? (
    <FinalForm />
  ) : (
    <MainContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'stretch',
          gap: 1,
        }}
      >
        <Stepper activeStep={currentStepIndex} alternativeLabel>
          {steps.map((steps, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box
          sx={{
            flexBasis: '100%',
            mx: 'auto',
            width: '100%',
            alignSelf: 'stretch',
          }}
        >
          <form noValidate onSubmit={onSubmit}>
            <Box>{step}</Box>
            <div
              style={{
                marginTop: '1rem',
                display: 'flex',
                gap: '.5rem',
                justifyContent: 'center',
              }}
            >
              {!isFirstStep && (
                <StepperButton
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={back}
                >
                  Back
                </StepperButton>
              )}
              <StepperButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                {loading ? 'Loading...' : isLastStep ? 'Submit' : 'Next'}
              </StepperButton>
            </div>
          </form>
        </Box>
      </Box>
    </MainContainer>
  )
}
