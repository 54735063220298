// Create a simple Lorem Ipsum page

import { ProductsServiceFormSchemaType } from '@lib/types/zod-schema'
import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material'
import { Control, Controller } from 'react-hook-form'

type ValuesFormProps = {
  control: Control<ProductsServiceFormSchemaType, any>
}

const style = {
  form: {
    mb: 2,
    width: 300,
  },
  alignment: {
    direction: 'column',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flex: 0.2,
    p: 2,
    width: '80%',
    justifyContent: 'center',

    flexDirection: 'column',
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sub_alignment: {
    mt: 6,
    p: 2,
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}

export function ProductServiceForm({ control }: ValuesFormProps) {
  const distMethods = [
    'Brick and Mortar',
    'E-Commerce',
    'Showrooms/Dealers/Distributors',
    'Web App',
  ]

  return (
    <>
      <Stack sx={style.alignment}>
        <Box sx={style.header}>
          <Typography p={2} fontSize={30} textAlign={'center'}>
            {' '}
            Tell us about your products/services
          </Typography>
        </Box>
        <Typography
          variant="body1"
          component="p"
          sx={{
            width: 300,
            mb: 2,
            textAlign: 'center',
          }}
        >
          How do you distribute your products or service? (Select all that
          apply)
        </Typography>
        <Controller
          name="distMethod"
          control={control}
          render={({
            field: { value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <Autocomplete
              id="vibes"
              onChange={(e, selectedValue) => {
                onChange(selectedValue)
              }}
              multiple
              onBlur={onBlur}
              value={value}
              options={distMethods}
              sx={style.form}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select"
                  error={Boolean(error)}
                  helperText={error ? error.message : null}
                />
              )}
            />
          )}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            variant="body1"
            component="p"
            sx={{
              width: 300,
              mb: 3,
              textAlign: 'center',
            }}
          >
            What types of products do you offer that you want potential brand
            partners to see on your profile?
          </Typography>
          <Controller
            name="productDescription"
            control={control}
            render={({
              field: { value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                multiline
                rows={4}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                helperText={error ? error.message : null}
                type="text"
                variant="outlined"
                label="Describe product categories, add link to products, etc.,"
                sx={style.form}
                InputLabelProps={{
                  style: { whiteSpace: 'normal' },
                }}
              />
            )}
          />
        </Box>
      </Stack>
    </>
  )
}
