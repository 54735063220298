// Create a simple Lorem Ipsum page

import {
  Box,
  Stack,
  Typography,
} from '@mui/material'

export function AboutForm() {
  return (
    <Stack>
      <Box>
        <Typography
          variant="h3"
          textAlign={'center'}
          component="h3"
          mt={8}
          mb={8}
        >
          You're one step away from creating your campaign!
        </Typography>
      </Box>
    </Stack>
  )
}
