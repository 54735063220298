// Create a simple Lorem Ipsum page

import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { VibeFormSchemaType } from '@lib/types/zod-schema'
import { Control, Controller } from 'react-hook-form'

type VibesFormProps = {
  control: Control<VibeFormSchemaType, any>
  contentText: ContentText
}

// type VibeData = {
//   vibe: string[]
//   vibeDesc: string
// }

// type UserFormProps = VibeData & {
//   updateFields: (fields: Partial<VibeData>) => void
// }

type ContentText ={
  header: string
  subheader: string
}

const style = {
  form: {
    mb: 2,
    width: 300,
    mt: 1,
  },
  alignment: {
    direction: 'column',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flex: 0.2,
    p: 2,
    width: '80%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sub_alignment: {
    mt: 6,
    p: 2,
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}

export function VibeForm({control, contentText}: VibesFormProps) {
  const vibes = [
    'Activist',
    'Empathic',
    'Retro',
    'Gothic',
    'Futuristic',
    'Inclusive',
    'Romantic',
    'Fun & Friendly',
    'Formal & Elegant',
    'Happy & Colorful',
    'Risk-taker',
    'Zen',
    'Dark / moody',
    'Traditional',
  ]

  return (
    <>
      <Stack sx={style.alignment}>
        <Box sx={style.header}>
          <Typography p={2} fontSize={30} textAlign={'center'}>
            {contentText.header}
          </Typography>
        </Box>
        <Box sx={style.header}>
          <Typography p={1} fontSize={15} textAlign={'center'}>
            {contentText.subheader}
          </Typography>
          <Controller
            name="vibe"
            control={control}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Autocomplete
                id="vibes"
                onChange={(e, selectedValue) => {
                  onChange(selectedValue)
                }}
                multiple
                onBlur={onBlur}
                value={value}
                options={vibes}
                sx={style.form}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    error={Boolean(error)}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            width={300}
            mb={1}
            mt={2}
          >
            Please further describe your vibe in your own words below.
          </Typography>
          <Controller
            name="vibeDesc"
            control={control}
            render={({
              field: { value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                multiline
                rows={4}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                placeholder='Ex: "I am a retro, fun and friendly, risk-taking, activist."'
                inputRef={ref}
                helperText={error ? error.message : null}
                type="text"
                label="Your Description..."
                variant="outlined"
                sx={style.form}
              />
            )}
          />
        </Box>
      </Stack>
    </>
  )
}
