// Create a simple Lorem Ipsum page

import {
  CampaignGoalsSchemaType,
} from '@lib/types/campaign-builder-zod'
import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material'
import { Control, Controller } from 'react-hook-form'

type CampaignGoalsFormProps = {
  control: Control<CampaignGoalsSchemaType, any>
}

const style = {
  form: {
    mb: 2,
    minWidth: 300,
  },
  alignment: {
    direction: 'column',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flex: 0.2,
    p: 2,
    gap: 2,
    width: '80%',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sub_alignment: {
    mt: 6,
    p: 2,
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}

const CAMPAIGN_GOALS = [
  'Expanding Product Lines',
  'Expanding Reach',
  'Enhancing Brand Image',
  'Developing Assets',
  'Targeting a Specific Demographic or Region'
]

export function CampaignGoalsForm({ control }: CampaignGoalsFormProps) {
  const campaignGoals = CAMPAIGN_GOALS

  return (
    <>
      <Stack sx={style.alignment}>
        <Box sx={style.header}>
          <Typography variant="h4" component={'h2'} textAlign={'center'}>
            Tell us the goals for this designer partnership.
          </Typography>
        </Box>
        <Controller
          name="campaignGoals"
          control={control}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => (
            <Autocomplete
              id="campaignGoals"
              onChange={(e, selectedValue) => {
                onChange(selectedValue)
              }}
              multiple
              onBlur={onBlur}
              value={value}
              options={campaignGoals}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Select Campaign Goals"
                  error={Boolean(error)}
                  sx={style.form}
                  helperText={error ? error.message : null}
                />
              )}
            />
          )}
        />
        <Box sx={style.header}>
          <Typography variant="h4" component={'h2'} textAlign={'center'}>
            Do you have specific KPIs planned?
          </Typography>
          <Typography variant="body1" component={'p'} textAlign={'center'}>
            Please describe what would a successful campaign looks like.
          </Typography>
        </Box>
        <Controller
          name="kpis"
          control={control}
          render={({
            field: { value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <TextField
              error={!!error}
              onChange={(e) => onChange(e.target.value)}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              helperText={error ? error.message : null}
              type="text"
              label="KPIs"
              id="kpis"
              placeholder='e.g. "Increase Instagram followers by 10%, increase sales by 20%"'
              variant="outlined"
              size="small"
              multiline
              rows={4}
              sx={style.form}
            />
          )}
        />
      </Stack>
    </>
  )
}
