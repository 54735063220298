// Create a simple Lorem Ipsum page

import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { TargetAudienceFormSchemaType } from '@lib/types/zod-schema'
import { Control, Controller } from 'react-hook-form'

type TargetAudienceProps = {
  control: Control<TargetAudienceFormSchemaType, any>
}

// type VibeData = {
//   vibe: string[]
//   vibeDesc: string
// }

// type UserFormProps = VibeData & {
//   updateFields: (fields: Partial<VibeData>) => void
// }

const style = {
  form: {
    mb: 3,
    width: 300,
    mt: 1,
  },
  alignment: {
    direction: 'column',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flex: 0.2,
    p: 2,
    width: '80%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sub_alignment: {
    mt: 6,
    p: 2,
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}

export function TargetAudienceForm(control: TargetAudienceProps) {
  const [selectedVibe, setSelectedVibe] = useState<string[]>([])

  // useEffect(() => {
  //   setSelectedVibe(vibe)
  // }, [vibe])

  // const handleChange = (value: string[]) => {
  //   updateFields({
  //     vibe: value,
  //   })
  // }

  const marketStrength = [
    'Residential',
    'Commercial',
    'Hospitality',
    'Distributors',
  ]
  return (
    <>
      <Stack sx={style.alignment}>
        <Box sx={style.header}>
          <Typography p={2} fontSize={30} textAlign={'center'}>
            {' '}
            Briefly describe your target audience
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            variant="body1"
            component="p"
            sx={{
              width: 300,
              mb: 3,
              textAlign: 'center',
            }}
          >
            How would you describe your ideal customers / target audience?
          </Typography>
          <Controller
            name="targetAudienceDescription"
            control={control.control}
            render={({
              field: { value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                multiline
                rows={4}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                helperText={error ? error.message : null}
                type="text"
                label="Your Description..."
                variant="outlined"
                sx={style.form}
              />
            )}
          />

          <Typography
            variant="body1"
            component="p"
            sx={{
              width: 300,
              mb: 3,
              textAlign: 'center',
            }}
          >
            Are there specific regions or markets where you want to strengthen
            your brand presence?
          </Typography>
          <Controller
            name="targetRegion"
            control={control.control}
            render={({
              field: { value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                multiline
                rows={4}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                helperText={error ? error.message : null}
                type="text"
                label="Your Description..."
                variant="outlined"
                sx={style.form}
              />
            )}
          />
            <Typography
              variant="body1"
              component="p"
              sx={{
                width: 300,
                mb: 3,
                textAlign: 'center',
              }}
            >
              In which markets are your customers (predominantly)?
            </Typography>
            <Controller
              name="marketStrength"
              control={control.control}
              render={({
                field: { value, onChange, onBlur, ref },
                fieldState: { error },
              }) => (
                <Autocomplete
                  id="vibes"
                  onChange={(e, selectedValue) => {
                    onChange(selectedValue)
                  }}
                  multiple
                  onBlur={onBlur}
                  value={value}
                  options={marketStrength}
                  sx={style.form}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Category"
                      error={Boolean(error)}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              )}
            />
          {/* <TextField
            id="vibeDescription"
            label="Your Description..."
            multiline
            rows={4}
            variant="outlined"
            value={vibeDesc}
            onChange={(e) =>
              updateFields({
                vibeDesc: e.target.value,
              })
            }
            fullWidth
            sx={{ mb: 2, width: 300 }}
          /> */}
        </Box>
      </Stack>
    </>
  )
}
