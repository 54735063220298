import {
  Box,
  Button,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
} from '@mui/material'
import { FormEvent, useEffect, useState } from 'react'

import { useMultistepForm } from '../../../lib/hooks/useMultistepForm'
import { AestheticForm } from '../AestheticForm'
import { ExclusionForm } from './ExclusionForm'
import OnboardingNotes from '../OnboardingNotes'
import { OnlinePresenceForm } from './OnlinePresenceForm'
import { OnlinePresenceForm2 } from './OnlinePresenceForm2'
import { ServiceDescForm } from './ServiceDescForm'
import { DesignerUserForm } from './DesignerUserForm'
import { ValuesForm } from '../ValuesForm'
import { VibeForm } from '../VibeForm'
import { FinalForm } from '../Final'
import { gql, useMutation } from '@apollo/client'

import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  MarketingExperienceSchemaType,
  SocialFormSchemaType,
  UserFormSchemaType,
  marketingExperienceSchema,
  socialFormSchema,
  userFormSchema,
  ServiceDescriptionFormSchemaType,
  serviceDescriptionFormSchema,
  VibeFormSchemaType,
  vibeSchema,
  InclusionExclusionFormSchemaType,
  inclusionExclusionSchema,
  ValuesFormSchemaType,
  valuesFormSchema,
} from '@lib/types/zod-schema'
import { Check } from '@mui/icons-material'
import onboardingLogo from '../../../assets/onboarding-logo.png'
import { useLocation } from 'react-router-dom'

const MainContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  fontFamily: 'Arial',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  alignSelf: 'stretch',
  // mt: 'auto',
  // mb: 'auto',
}))

const StepperButton = styled(Button)(({ theme }) => ({
  mr: theme.spacing(1),
  px: theme.spacing(4),
}))

type FormData = UserFormSchemaType &
  SocialFormSchemaType &
  ServiceDescriptionFormSchemaType &
  VibeFormSchemaType &
  InclusionExclusionFormSchemaType & {
    yearsExperience: number
    marketingActivities: string[]
    content: string[]
    currentlyInAgency: boolean
    inHouseContent: boolean
    aestheticData: AestheticData
    value: string[]
    valueDesc: string
    // politicalDesc: string
    // excludedStyle: string[]
    // excludedValue: string[]
    // excludedVibe: string[]
  }
type ContentText = {
  header: string
  subheader: string
}

type AestheticData = {
  Minimalist: number
  Maximalist: number
  Modern: number
  Traditional: number
  Boho: number
  Scandi: number
  Eclectic: number
  Simple: number
  New: number
  Vintage: number
  ReadyMade: number
  DIY: number
  BigBox: number
  SmallBusiness: number
  Aspirational: number
  Attainable: number
  Luxury: number
  BudgetFriendly: number
}

const INITIAL_DATA: FormData = {
  first_name: '',
  last_name: '',
  business_name: '',
  email: '',
  website: '',
  newsletter: false,
  socials: {
    instagram: { url: '', followers: null },
    facebook: { url: '', followers: null },
    pinterest: { url: '', followers: null },
    tiktok: { url: '', followers: null },
    twitter: { url: '', followers: null },
    youtube: { url: '', followers: null },
    linkedin: { url: '', followers: null },
  },
  yearsExperience: 0,
  marketingActivities: [],
  content: [],
  currentlyInAgency: false,
  inHouseContent: false,
  companyData: {
    companyType: '',
    category: '',
    description: '',
    companyName: '',
  },
  aestheticData: {
    Minimalist: 2,
    Maximalist: 2,
    Modern: 2,
    Traditional: 2,
    Boho: 2,
    Scandi: 2,
    Eclectic: 2,
    Simple: 2,
    New: 2,
    Vintage: 2,
    ReadyMade: 2,
    DIY: 2,
    BigBox: 2,
    SmallBusiness: 2,
    Aspirational: 2,
    Attainable: 2,
    Luxury: 2,
    BudgetFriendly: 2,
  },
  vibe: [],
  vibeDesc: '',
  value: [],
  valueDesc: '',
  // politicalDesc: '',
  // excludedStyle: [],
  // excludedValue: [],
  // excludedVibe: [],
  inclusionDesc: '',
  exclusionDesc: '',
}

const LOAD_DATA = gql`
  mutation LoadData($input: FormData!) {
    loadData(input: $input) {
      message
      success
    }
  }
`

export default function DesignerOnboarding() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const firstname = searchParams.get('firstname') || ''
  const lastname = searchParams.get('lastname') || ''
  const businessname = searchParams.get('business_name') || ''
  const email = searchParams.get('email') || ''
  const website = searchParams.get('website') || ''
  const newsletter = searchParams.get('newsletter')
    ? searchParams.get('newsletter')?.toLowerCase() === 'true'
    : false

  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const [loadDataMutation] = useMutation(LOAD_DATA)
  const [formData, setFormData] = useState(INITIAL_DATA)
  const [final, setFinal] = useState(false)

  const vibeContentText: ContentText = {
    header:
      'Share your vibe to help potential brand partners get to know you better.',
    subheader:
      'Which of the following best describes the content you share on social media? Choose all that apply.',
  }

  const valuesContentText: ContentText = {
    header: `How would you describe your brand's values?.`,
    subheader: `Share your values to help potential brand partners get to know you and your brand's core beliefs.`,
  }

  const onlinePresenceContentText: ContentText = {
    header: `Describe your influence in terms brands can search`,
    subheader: `What types of content do you want to create with brand partners?
    Choose all that apply:`,
  }

  const onboardingSteps = [
    'Online Presence',
    'Your Services',
    'Aesthetic & Vibe',
    'Values',
    'Project Portfolio',
    'Expectations',
  ]

  const { control: controlUserForm, handleSubmit: handleSubmitUserForm } =
    useForm<UserFormSchemaType>({
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      resolver: zodResolver(userFormSchema),
      defaultValues: {
        first_name: firstname,
        last_name: lastname,
        email: email,
        business_name: businessname,
        website: website,
        newsletter: newsletter,
      },
    })

  const { control: controlSocialForm, handleSubmit: handleSubmitSocialForm } =
    useForm<SocialFormSchemaType>({
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      resolver: zodResolver(socialFormSchema),
      defaultValues: {
        socials: { ...INITIAL_DATA.socials },
      },
    })

  const {
    control: controlServiceDescriptionForm,
    handleSubmit: handleSubmitServiceDescriptionForm,
  } = useForm<ServiceDescriptionFormSchemaType>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(serviceDescriptionFormSchema),
    defaultValues: {
      companyData: { ...INITIAL_DATA.companyData },
    },
  })

  const {
    control: controlMarketingExpForm,
    handleSubmit: handleSubmitMarketingExpForm,
  } = useForm<MarketingExperienceSchemaType>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(marketingExperienceSchema),
    defaultValues: {
      marketingActivities: [],
      content: [],
      currentlyInAgency: false,
      inHouseContent: false,
      yearsExperience: 0,
    },
  })

  const { control: controlVibesForm, handleSubmit: handleSubmitVibesForm } =
    useForm<VibeFormSchemaType>({
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      resolver: zodResolver(vibeSchema),
      defaultValues: {
        vibe: [],
        vibeDesc: '',
      },
    })

  const {
    control: controlInclusionExclusionForm,
    handleSubmit: handleSubmitInclusionExclusionForm,
  } = useForm<InclusionExclusionFormSchemaType>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(inclusionExclusionSchema),
    defaultValues: {
      inclusionDesc: '',
      exclusionDesc: '',
    },
  })
  // Value Form useForm
  const { control: controlValuesForm, handleSubmit: handleSubmitValuesForm } =
    useForm<ValuesFormSchemaType>({
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      resolver: zodResolver(valuesFormSchema),
      defaultValues: {
        value: [],
        valueDesc: '',
      },
    })

  function updateFields(fields: Partial<FormData>) {
    setFormData((prev: FormData) => {
      return { ...prev, ...fields }
    })
  }

  function onUpdateData<T extends FieldValues>(
    stepData: T,
  ): SubmitHandler<T> | void {
    console.log('stepData: ', stepData)
    setFormData((prev: FormData) => {
      return { ...prev, ...stepData }
    })

    return next()
  }

  const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } =
    useMultistepForm([
      <DesignerUserForm control={controlUserForm} />,
      <OnboardingNotes first_name={formData.first_name} steps={onboardingSteps} />,
      <OnlinePresenceForm control={controlSocialForm} />,
      <OnlinePresenceForm2
        control={controlMarketingExpForm}
        contentText={onlinePresenceContentText}
      />,
      <ServiceDescForm control={controlServiceDescriptionForm} />,
      <AestheticForm {...formData} updateFields={updateFields} />,
      <VibeForm control={controlVibesForm} contentText={vibeContentText} />,
      <ValuesForm
        control={controlValuesForm}
        contentText={valuesContentText}
      />,
      <ExclusionForm control={controlInclusionExclusionForm} />,
    ])

  useEffect(() => {
    async function submitData() {
      try {
        console.log('submitting data...')
        setLoading(true)
        const { data } = await loadDataMutation({
          variables: {
            input: formData,
          },
        })
        if (data && data?.loadData) {
          const { message, success } = data.loadData
          console.log(`Message: ${message}, Success: ${success}`)
          if (success) {
            setFinal(true)
            console.log('Data submitted successfully.')
          }
        } else {
          console.error('Unexpected response format:', data)
        }
      } catch (error) {
        console.error('Error executing LOAD_DATA mutation:', error)
      } finally {
        setLoading(false) // Set loading to false after the mutation is complete
      }
    }

    if (done && formData.inclusionDesc && formData.exclusionDesc) {
      submitData()
    }
  }, [done, formData, loadDataMutation])

  function onSubmit(e: FormEvent) {
    e.preventDefault()
    e.stopPropagation()

    switch (currentStepIndex) {
      case 0:
        void handleSubmitUserForm(onUpdateData)(e)
        break
      case 2:
        console.log('handleSubmitSocialForm')
        void handleSubmitSocialForm(onUpdateData)(e)
        break
      case 3:
        console.log('handleSubmitMarketingExpForm')
        void handleSubmitMarketingExpForm(onUpdateData)(e)
        break
      case 4:
        void handleSubmitServiceDescriptionForm(onUpdateData)(e)
        break
      case 6:
        void handleSubmitVibesForm(onUpdateData)(e)
        break
      case 7:
        void handleSubmitValuesForm(onUpdateData)(e)
        break
      case 8:
        void handleSubmitInclusionExclusionForm(onUpdateData)(e)
        setDone(true)
        break
      default:
        return next()
    }
  }

  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean }
  }>(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundColor: theme.palette.secondary.main,
    }),
  }))

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {completed ? <Check /> : <span>{props.icon}</span>}
      </ColorlibStepIconRoot>
    )
  }

  return final === true ? (
    <FinalForm />
  ) : (
    <MainContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'stretch',
          gap: 1,
        }}
      >
        <Stepper activeStep={currentStepIndex} alternativeLabel>
          {steps.map((steps, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'stretch',
            m: 2,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={onboardingLogo} alt="logo" style={{ width: '200px' }} />
        </Box>

        <Box
          sx={{
            flexBasis: '100%',
            mx: 'auto',
            width: '100%',
            alignSelf: 'stretch',
          }}
        >
          <form noValidate onSubmit={onSubmit}>
            <Box>{step}</Box>
            <div
              style={{
                marginTop: '1rem',
                display: 'flex',
                gap: '.5rem',
                justifyContent: 'center',
              }}
            >
              {!isFirstStep && (
                <StepperButton
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={back}
                >
                  Back
                </StepperButton>
              )}
              <StepperButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                {loading ? 'Loading...' : isLastStep ? 'Submit' : 'Next'}
              </StepperButton>
            </div>
          </form>
        </Box>
      </Box>
    </MainContainer>
  )
}
