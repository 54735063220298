import { BrandDescriptionFormSchemaType } from '@lib/types/zod-schema'
import { Box, Stack, TextField, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { useState } from 'react'
import { Control, Controller } from 'react-hook-form'

type ServiceDescriptionFormProps = {
  control: Control<BrandDescriptionFormSchemaType, any>
}
const style = {
  form: {
    mb: 2,
    width: 300,
  },
  alignment: {
    direction: 'column',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flex: 0.2,
    p: 2,
    width: '80%',
    justifyContent: 'center',
  },
}

export function BrandDescriptionForm(control: ServiceDescriptionFormProps) {
  const companySize = [
    'Small (under 20 employees)',
    'Medium (20 to 500)',
    'Large Firm (500+)',
  ]
  const companyTypes = ['Manufacturer', 'Retailer', 'Other']

  const industry = [
    'Furniture',
    'Flooring',
    'Home Decor',
    'Textiles/Soft Goods',
    'Lighting',
    'Roofing/Siding',
    'Cabinetry',
    'Hardware',
    'Masonry/Hardscaping',
    'Windows/Doors',
    'Other',
  ]

  const companyType = control.control._formValues.companyData.companyType
  const industryType = control.control._formValues.companyData.industry

  const [selectedCompanyType, setSelectedCompanyType] = useState(
    companyType && companyTypes.includes(companyType)
      ? companyType
      : companyType
      ? 'Other'
      : '',
  )
  const [selectedIndustry, setSelectedIndustry] = useState(
    industryType && industry.includes(industryType)
      ? industryType
      : industryType
      ? 'Other'
      : '',
  )

  const handleIndustryOnChange = (
    setValue: any,
    onBlur: any,
    value: string | null,
  ) => {
    if (value !== 'Other') {
      setValue(value)
      setSelectedIndustry(value)
      onBlur()
      return
    }
    setValue('')
    setSelectedIndustry('Other')
  }

  const handleTypeOnChange = (
    setValue: any,
    onBlur: any,
    value: string | null,
  ) => {
    if (value !== 'Other') {
      setValue(value)
      setSelectedCompanyType(value)
      onBlur()
      return
    }
    setValue('')
    setSelectedCompanyType('Other')
  }
  return (
    <>
      <Stack sx={style.alignment}>
        <Box sx={style.header}>
          <Typography p={2} fontSize={30} textAlign={'center'}>
            Tell us about your brand.
          </Typography>
        </Box>
        <Controller
          name="companyData.companyType"
          control={control.control}
          render={({
            field: { value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <Autocomplete
              id="companyType"
              onChange={(e, selectedValue) => {
                handleTypeOnChange(onChange, onBlur, selectedValue)
              }}
              // onBlur={onBlur}
              value={selectedCompanyType}
              options={companyTypes}
              sx={style.form}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Your Company Type"
                  error={Boolean(error)}
                  helperText={error ? error.message : null}
                />
              )}
            />
          )}
        />

        {selectedCompanyType === 'Other' && (
          <Controller
            name="companyData.companyType"
            control={control.control}
            render={({
              field: { value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                helperText={error ? error.message : null}
                type="text"
                label="Company Type"
                variant="outlined"
                sx={style.form}
              />
            )}
          />
        )}

        <Controller
          name="companyData.companySize"
          control={control.control}
          render={({
            field: { value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <Autocomplete
              id="companySize"
              onChange={(e, selectedValue) => {
                onChange(selectedValue)
              }}
              onBlur={onBlur}
              value={value}
              options={companySize}
              sx={style.form}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Your Company Size"
                  error={Boolean(error)}
                  helperText={error ? error.message : null}
                />
              )}
            />
          )}
        />

        <Controller
          name="companyData.industry"
          control={control.control}
          render={({
            field: { value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <Autocomplete
              id="industry"
              onChange={(e, selectedValue) => {
                handleIndustryOnChange(onChange, onBlur, selectedValue)
              }}
              // onBlur={onBlur}
              value={selectedIndustry}
              options={industry}
              sx={style.form}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Your Industry"
                  error={Boolean(error)}
                  helperText={error ? error.message : null}
                />
              )}
            />
          )}
        />

        {selectedIndustry === 'Other' && (
          <Controller
            name="companyData.industry"
            control={control.control}
            render={({
              field: { value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                helperText={error ? error.message : null}
                type="text"
                label="Industry"
                variant="outlined"
                sx={style.form}
              />
            )}
          />
        )}

        <Typography
          variant="body1"
          component="p"
          sx={{
            width: 300,
            mb: 3,
            textAlign: 'center',
          }}
        >
          How many years have you been in business?
        </Typography>

        <Controller
          name="companyData.yearsInBusiness"
          control={control.control}
          render={({
            field: { value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <TextField
              required
              error={!!error}
              onChange={(e) => onChange(parseInt(e.target.value))}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              helperText={error ? error.message : null}
              type="number"
              label="Years in Business"
              id="yearsInBusiness"
              variant="outlined"
              sx={style.form}
            />
          )}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            width={300}
            mb={1}
            mt={2}
          >
            Briefly describe your brand's story
          </Typography>
          <Controller
            name="companyData.description"
            control={control.control}
            render={({
              field: { value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                multiline
                rows={4}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                helperText={error ? error.message : null}
                type="text"
                label="Service Description"
                variant="outlined"
                sx={style.form}
              />
            )}
          />
        </Box>
      </Stack>
    </>
  )
}
