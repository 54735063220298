import {
  CampaignProductListSchemaType,
  CampaignProductSchemaType,
} from '@lib/types/campaign-builder-zod'
import {
  Box,
  Stack,
  TextField,
  Typography,
  Button,
} from '@mui/material'
import { useState } from 'react'
import { Control, Controller, UseFormSetValue, set } from 'react-hook-form'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RestartAltIcon from '@mui/icons-material/RestartAlt';

type CampaignProductFormProps = {
  control: Control<CampaignProductListSchemaType, any>,
  setValue: UseFormSetValue<CampaignProductListSchemaType>
}

const style = {
  form: {
    mb: 2,
  },
  alignment: {
    direction: 'column',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flex: 0.2,
    p: 2,
    gap: 2,
    width: '80%',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sub_alignment: {
    mt: 6,
    p: 2,
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}

export function CampaignProductForm({ control, setValue }: CampaignProductFormProps) {
  const [products, setProducts] = useState<CampaignProductSchemaType[]>(
    control._formValues.products
  )

  const addProduct = () => {
    setProducts([
      ...products,
      {
        productName: '',
        productDescription: '',
      },
    ])
  }

  const resetProducts = () => {
    const defaultValues = [
      {
        productName: '',
        productDescription: '',
      },
    ]

    setProducts(defaultValues)
    setValue('products', defaultValues)
  }

  return (
    <>
      <Stack sx={style.alignment}>
        <Box sx={style.header}>
          <Typography variant="h4" component={'h2'} textAlign={'center'}>
            Tell us about the product(s) you're promoting
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            {products.map((product, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                  justifyContent: 'center',
                }}
              >
                <Controller
                  name={`products.${index}.productName`}
                  control={control}
                  render={({
                    field: { value, onChange, onBlur, ref },
                    fieldState: { error },
                  }) => (
                    <TextField
                      required
                      error={!!error}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      value={value}
                      inputRef={ref}
                      helperText={error ? error.message : null}
                      type="text"
                      label="Product Name"
                      id={`products.${index}.productName`}
                      variant="outlined"
                      size="small"
                      sx={{ my: 1, flex: 0.4 }}
                    />
                  )}
                />
                <Controller
                  name={`products.${index}.productDescription`}
                  control={control}
                  render={({
                    field: { value, onChange, onBlur, ref },
                    fieldState: { error },
                  }) => (
                    <TextField
                      error={!!error}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      value={value}
                      inputRef={ref}
                      helperText={error ? error.message : null}
                      type="text"
                      label="Product Description"
                      id={`products.${index}.productDescription`}
                      variant="outlined"
                      size="small"
                      sx={{ my: 1, flex: 0.6 }}
                    />
                  )}
                />
              </Box>
            ))}
          </Box>
          <Box>
            <Button color='secondary' fullWidth onClick={addProduct} variant="outlined" startIcon={<AddCircleOutlineIcon />}>
              Add Another Product
            </Button>
            <Button fullWidth variant="text" onClick={resetProducts} startIcon={<RestartAltIcon />}>
              Reset
            </Button>
          </Box>
        </Box>
      </Stack>
    </>
  )
}
