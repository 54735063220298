// Create a simple Lorem Ipsum page

import { FormContainer } from '@components/ui/StyledComponents'
import { UserFormSchemaType } from '@lib/types/zod-schema'
import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { Control, Controller } from 'react-hook-form'

type UserFormProps = {
  control: Control<UserFormSchemaType, any>
}

export function DesignerUserForm({ control }: UserFormProps) {
  return (
    <Stack>
      <Box>
        <Typography textAlign={'center'} variant="subtitle1" component="h2" my={2}>
          Welcome to UltraCollective
        </Typography>
        <Typography
          variant="h4"
          fontWeight={700}
          textAlign={'center'}
          component="h3"
          my={2}
        >
          We're glad you're here! Let's get started building your profile:
        </Typography>
      </Box>
      <FormContainer>
        <Controller
          name="first_name"
          control={control}
          render={({
            field: { value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <TextField
              required
              error={!!error}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              helperText={error ? error.message : null}
              type="text"
              label="First Name"
              id="first_name"
              variant="outlined"
              size="medium"
            />
          )}
        />
        <Controller
          name="last_name"
          control={control}
          render={({
            field: { value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <TextField
              required
              error={!!error}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              helperText={error ? error.message : null}
              type="text"
              label="Last Name"
              id="last_name"
              variant="outlined"
              size="medium"
            />
          )}
        />
        <Controller
          name="business_name"
          control={control}
          render={({
            field: { value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <TextField
              required = {false}
              error={!!error}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              helperText={error ? error.message : null}
              type="text"
              label="Business Name"
              id="business_name"
              variant="outlined"
              size="medium"
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({
            field: { value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <TextField
              required
              error={!!error}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              helperText={error ? error.message : null}
              type="email"
              label="E-mail"
              id="email"
              variant="outlined"
              size="medium"
            />
          )}
        />
        <Controller
          name="website"
          control={control}
          render={({
            field: { value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <TextField
              required={false}
              error={Boolean(error)}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              helperText={Boolean(error) ? error?.message : null}
              type="text"
              label="Website"
              id="website"
              variant="outlined"
              size="medium"
            />
          )}
        />
        <Controller
          name="newsletter"
          control={control}
          render={({
            field: { value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <FormControlLabel
              control={
                <Switch
                  id="newsletter"
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  onChange={onChange}
                  onBlur={onBlur}
                  checked={value}
                  inputRef={ref}
                />
              }
              label={
                <Typography variant="body2" component="p">
                  Sign up for news.
                </Typography>
              }
            />
          )}
        />
      </FormContainer>
    </Stack>
  )
}
