import Title from '@components/ui/Title'
import { getUrlPath } from '@lib/utils/url'
import { Avatar, Paper } from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import DesignerOnboarding from '@pages/onboarding/designer-onboarding/DesignerOnboarding'
import React from 'react'

const style = {
  display: 'flex',
  flexDirection: 'column' as 'column',
  gap: 2,
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  width: '50vw',
  transform: 'translate(-50%, -50%)',
  maxwidth: '50vh',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  outline: 'none',
  overflow: 'auto',
}

interface ProfileInfoModalProps {
  open: boolean
  onClose: () => void
  data: any
}

function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  }
}

const ProfileInfoModal: React.FC<ProfileInfoModalProps> = ({
  open,
  onClose,
  data,
}) => {
  return (
    <div>
      <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title">
        <Box sx={style}>
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              p: 2,
              '& > :not(style)': {
                mr: 4,
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                p: 2,
                '& > :not(style)': {
                  mr: 4,
                },
              }}
            >
              <Avatar
                {...stringAvatar(data.designer_name)}
                sx={{ width: 150, height: 150, fontSize: '80px' }}
                variant="square"
              />
              <Box>
                <Typography variant="h3" component="div">
                  <Box sx={{ fontWeight: 'bold' }}>{data.designer_name}</Box>
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <ProfileInfo label="Email" value={data.email} />
                  <ProfileInfo
                    label="Total Reach"
                    value={data.total_reach.toLocaleString()}
                  />
                  <ProfileInfo
                    label="Values & Attributes"
                    value={data.values.join(', ')}
                  />
                  <ProfileInfo
                    label="Aesthetics & Vibe"
                    value={
                      data.vibes.join(', ') + ', ' + data.styles.join(', ')
                    }
                  />
                  <ProfileInfo
                    label="Content"
                    value={data.content.join(', ')}
                  />
                  <ProfileInfo
                    label="Activities"
                    value={data.activities.join(', ')}
                  />
                </Box>
              </Box>
            </Box>
            <Typography variant="h3" component="div">
              <Box sx={{ fontWeight: 'bold' }}>Recent Projects</Box>
            </Typography>
            <Box
              sx={{
                height: 400,
                border: '1px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {!data.projects ? (
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ color: 'rgba(0, 0, 0, 0.5)' }}
                >
                  <Box sx={{ fontWeight: 'bold' }}>No Projects Found</Box>
                </Typography>
              ) : (
                <></>
              )}
            </Box>
          </Paper>
        </Box>
      </Modal>
    </div>
  )
}

interface ProfileInfoProps {
  label: string
  value: string | number
}

const ProfileInfo: React.FC<ProfileInfoProps> = ({ label, value }) => {
  return (
    <Typography variant="body1" component="div" sx={{ mb: 1 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          
          gap: 1,
        }}
      >
        <Box
          sx={{ fontWeight: 'bold',  maxWidth: '95px' }}
        >
          {label}:{' '}
        </Box>
        <Box>{value}</Box>
      </Box>
    </Typography>
  )
}

export default ProfileInfoModal
