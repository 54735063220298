import { fetchCampaignRecommendations } from '@lib/services/campaign'
import {
  Campaign,
  CampaignFormAnswers,
  CampaignPlanAPIRequest,
  CampaignRecoAPIRequest,
  FormAnswers,
} from '@lib/types/common-types'
import { storeObject } from '@lib/utils/storage'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { uid } from 'uid'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function computeCampaignBudget(
  monetaryValue: number | null,
  productValue: number | null,
) {
  if (monetaryValue && productValue) {
    return monetaryValue + productValue
  } else if (monetaryValue) {
    return monetaryValue
  } else if (productValue) {
    return productValue
  } else {
    return 0
  }
}

function mapAnswersToRequest(
  answers: CampaignFormAnswers,
): CampaignRecoAPIRequest {
  return {
    brand_name: answers.brandName,
    campaign_concept: answers.campaignConceptsDescription,
    campaign_goal: answers.campaignGoals.join(', '), // Assuming campaignGoals is an array of strings
    target_audience: answers.audienceDescription,
    campaign_budget:
      '$' +
      computeCampaignBudget(
        answers.totalMonetaryValue,
        answers.totalProductValue,
      ),
    timeline: `${answers.timelineStartDate} - ${answers.timelineEndDate}`,
    key_products: answers.products.map(
      (product) =>
        product.productName +
        (product.productDescription ? ' - ' + product.productDescription : ''),
    ),
    target_kpis: answers.kpis,
    designers: answers.selectedDesigners,
  }
}

const mapCampaignConceptToRequest = (
  campaignConcept: Campaign & { instructions: string | null },
  formAnswers: CampaignFormAnswers,
): CampaignPlanAPIRequest => {
  return {
    campaign_name: campaignConcept.campaign_name,
    campaign_description: campaignConcept.description,
    assets_count: null,
    campaign_styles: formAnswers.campaignStyles,
    showcase_location: formAnswers.campaignShowcaseLocation,
    instructions: campaignConcept.instructions,
  }
}

const CampaignRecommendations = () => {
  const location = useLocation()
  const formAnswers: FormAnswers | null = location.state || null
  const [error, setError] = React.useState<boolean>(false)
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)
  const [campaignRecommendations, setCampaignRecommendations] = React.useState<
    Campaign[]
  >([])

  const fetchCampaigns = React.useCallback(() => {
    if (formAnswers && formAnswers?.formData && formAnswers?.brandData) {
      console.log('formAnswers', formAnswers)
      setLoading(true)
      const mappedAnswers = mapAnswersToRequest({
        ...formAnswers.formData,
        brandName: formAnswers.brandData.businessName,
      })
      return fetchCampaignRecommendations(
        mappedAnswers,
        'campaign-recommendations',
      )
        .then((res) => {
          console.log('res', res)
          setCampaignRecommendations((prev) => [...prev, ...res])
        })
        .catch(() => {
          setError(true)
          setErrorMessage(
            'Sorry, we failed to fetch your recommendations. Please try again.',
          )
        })
        .finally(() => setLoading(false))
    } else {
      setError(true)
      setErrorMessage(
        'Sorry, we could not find your answers. Please try again.',
      )
    }
  }, [formAnswers])

  React.useEffect(() => {
    fetchCampaigns()
  }, [fetchCampaigns])

  const skeleton = (
    <>
      <LinearProgress color="secondary" />
      {[1, 2, 3].map((item, index) => (
        <Card key={index} sx={{ width: 400 }}>
          <CardContent>
            <Skeleton
              variant="text"
              width={'50%'}
              sx={{ fontSize: '1.5rem' }}
            />
            <Skeleton variant="text" width={'30%'} sx={{ fontSize: '1rem' }} />
            <Skeleton
              variant="text"
              width={'100%'}
              sx={{ fontSize: '0.5rem' }}
            />
            <Skeleton
              variant="text"
              width={'60%'}
              sx={{ fontSize: '0.5rem' }}
            />
            <Skeleton variant="text" width={'30%'} sx={{ fontSize: '1rem' }} />
            <Skeleton
              variant="text"
              width={'100%'}
              sx={{ fontSize: '0.5rem' }}
            />
            <Skeleton
              variant="text"
              width={'60%'}
              sx={{ fontSize: '0.5rem' }}
            />
          </CardContent>
        </Card>
      ))}
    </>
  )

  return (
    <>
      <Paper
        elevation={3}
        sx={{ display: 'flex', flexDirection: 'column', p: 2, minHeight: 600 }}
      >
        <Typography align="center" variant="h4" component="h1">
          Campaign Recommendations
        </Typography>
        <Typography align="center" gutterBottom variant="body1" component="p">
          Based on your answers, we recommend the following campaigns:
        </Typography>
        {error && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              flexGrow: 1,
            }}
          >
            <Typography
              align="center"
              gutterBottom
              variant="body1"
              fontWeight={'bold'}
              fontSize={'1.5rem'}
              component="p"
            >
              {errorMessage}
            </Typography>
            <Button
              size="small"
              sx={{ alignSelf: 'center' }}
              variant="contained"
              color="primary"
            >
              <Link to="/brands/campaigns/builder">Try again.</Link>
            </Button>
          </Box>
        )}
        <Stack spacing={2}>
          {campaignRecommendations.map((c: Campaign, index) => (
            <Card
              elevation={2}
              key={index}
              sx={{ bgcolor: 'secondary', maxWidth: '100%' }}
            >
              <CardContent>
                <Typography
                  align={'center'}
                  gutterBottom
                  variant="h5"
                  component="h1"
                >
                  {c.campaign_name}
                </Typography>
                <Typography gutterBottom variant="h6" component="h2">
                  Description
                </Typography>
                <Typography gutterBottom variant="body2" color="text.secondary">
                  {c.description}
                </Typography>
              </CardContent>
              {!error && (
                <CardActions>
                  <CampaignConceptModal
                    campaignConcept={c}
                    index={index}
                    formAnswers={formAnswers as FormAnswers}
                    setCampaignRecommendations={setCampaignRecommendations}
                  />
                </CardActions>
              )}
            </Card>
          ))}

          {loading ? (
            skeleton
          ) : (
            <Box
              sx={{
                mt: 4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                size="small"
                sx={{ alignSelf: 'center' }}
                variant="contained"
                color="primary"
                onClick={fetchCampaigns}
              >
                Create More Concepts
              </Button>
            </Box>
          )}
        </Stack>
      </Paper>
    </>
  )
}

type CampaignPlanModalProps = {
  campaignConcept: Campaign
  index: number
  formAnswers: FormAnswers
  setCampaignRecommendations: React.Dispatch<React.SetStateAction<Campaign[]>>
}

const CampaignConceptModal: React.FC<CampaignPlanModalProps> = ({
  campaignConcept,
  formAnswers,
  setCampaignRecommendations,
  index,
}) => {
  const [conceptForm, setConceptForm] = React.useState({
    campaign_name: campaignConcept?.campaign_name || '',
    campaign_description: campaignConcept?.description || '',
    instructions: '',
  })
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleCreateCampaign = () => {
    setCampaignRecommendations((prev) => {
      const newCampaigns = [...prev]
      newCampaigns[index] = {
        campaign_name: conceptForm.campaign_name,
        description: conceptForm.campaign_description,
      }
      return newCampaigns
    })

    if (
      conceptForm.campaign_name &&
      conceptForm.campaign_description &&
      formAnswers
    ) {
      const mappedFormAnswers = mapAnswersToRequest({
        ...formAnswers.formData,
        brandName: formAnswers.brandData.businessName,
      })
      const mappedCampaignConcept = mapCampaignConceptToRequest(
        {
          ...campaignConcept,
          campaign_name: conceptForm.campaign_name,
          description: conceptForm.campaign_description,
          instructions: conceptForm?.instructions || null,
        },
        formAnswers.formData,
      )
      const campaignPlanInput = {
        ...mappedFormAnswers,
        ...mappedCampaignConcept,
      }
      const gen_key = uid()
      const key = `campaignPlanInput_${gen_key}`

      storeObject(key, campaignPlanInput)

      window.open(
        `/brands/campaigns/${gen_key}`,
        '_blank',
        'noopener noreferrer',
      )
    }
  }

  return (
    <div>
      <Button color="secondary" onClick={handleOpen} variant="contained">
        Select Concept
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Modify Concept</DialogTitle>

        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>
              <Typography variant="body1" component="p">
                Please review the Campaign Plan below and make any changes you
                would like.
              </Typography>
            </DialogContentText>
            <TextField
              required
              onChange={(e) => {
                setConceptForm((prev) => ({
                  ...prev,
                  campaign_name: e.target.value,
                }))
              }}
              value={conceptForm.campaign_name}
              type="text"
              label="Campaign Title"
              id="campaignTitle"
              variant="outlined"
              size="small"
            />
            <TextField
              required
              onChange={(e) => {
                setConceptForm((prev) => ({
                  ...prev,
                  campaign_description: e.target.value,
                }))
              }}
              value={conceptForm.campaign_description}
              type="text"
              label="Campaign Description"
              id="campaignDescription"
              variant="outlined"
              size="small"
              multiline
              rows={4}
            />
            <TextField
              onChange={(e) => {
                setConceptForm((prev) => ({
                  ...prev,
                  instructions: e.target.value,
                }))
              }}
              value={conceptForm.instructions}
              helperText="You can provide any additional instructions for our AI to create your Campaign Plan."
              placeholder={`e.g. "Please include a budget breakdown in the campaign."`}
              type="text"
              label="Instructions for AI"
              id="instructions"
              variant="outlined"
              size="small"
              multiline
              rows={3}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleCreateCampaign}
          >
            Create Campaign
          </Button>
          <Button size="small" variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CampaignRecommendations
